import { twMerge } from 'tailwind-merge';
import {
  getCellVisibillityClasses,
  type ResponsiveSources,
} from './power-cell-getters';
import { ResponsiveText, type TextComponent } from './responsive-text';

const textContainerClassName =
  'mb-label-b ml-label-l mr-label-r mt-label-t inline-flex whitespace-pre-wrap rounded-label bg-label pb-label-b pl-label-l pr-label-r pt-label-t font-label-font-family text-label-font-size tracking-label-letter-spacing text-label-color group-hover:text-label-color-hover text-transform group-hover:bg-label-hover label-text-decoration';

export default function LabelV2({
  responsiveText,
  className,
  componentTextLabelTypography = 'p',
  isLoading,
}: {
  responsiveText: ResponsiveSources;
  className?: string;
  componentTextLabelTypography?: TextComponent;
  isLoading?: boolean;
}) {
  if (isLoading) {
    return (
      <Skeleton
        responsiveText={responsiveText}
        className={className}
        componentTextLabelTypography={componentTextLabelTypography}
      />
    );
  }

  return (
    <div className={getCellVisibillityClasses(responsiveText, className)}>
      <div className={textContainerClassName}>
        <ResponsiveText
          responsiveText={responsiveText}
          componentTextLabelTypography={componentTextLabelTypography}
        />
      </div>
    </div>
  );
}

function Skeleton({
  responsiveText,
  className,
  componentTextLabelTypography,
}: {
  responsiveText: ResponsiveSources;
  className?: string;
  componentTextLabelTypography?: TextComponent;
}) {
  return (
    <div className={getCellVisibillityClasses(responsiveText, className)}>
      <div
        className={twMerge(
          textContainerClassName,
          'group-hover:bg-skeleton_background_color',
          'animate-pulse bg-skeleton_background_color'
        )}
      >
        <ResponsiveText
          responsiveText={{
            mobile: '                   ',
            tablet: '                   ',
            desktop: '                   ',
            'large-desktop': '                   ',
          }}
          componentTextLabelTypography={componentTextLabelTypography}
        />
      </div>
    </div>
  );
}
