import { useEffect, useState } from 'react';
import logger from '~/services/logger';
import type { Feed, FeedEntry } from '~/services/layout/index.server.ts';
import {
  filterSkeletonEntries,
  generateSkeletonEntries,
  getLimit,
} from '~/services/layout/layout';
import { getClientFeedUrl } from '~/services/request/index.ts';
import { isValidUrl } from '~/utils/validations.ts';

const MAX_EMPTY_NEXT_FEED_TRIES: number = 4;
const NEXT_FEED_TIMEOUT: number = 20_000;
const NEXT_FEED_SKELETON_ENTRIES: number = 5;

function nextFeedUrlSetter({
  feed,
  setNextFeedUrl,
}: {
  feed: Feed | undefined;
  setNextFeedUrl: any;
}): void {
  const nextFeedUrl: string | undefined = feed?.next;

  const isValidNextFeedUrl: boolean = isValidUrl(nextFeedUrl);

  if (!isValidNextFeedUrl) {
    setNextFeedUrl(undefined);
    return;
  }

  setNextFeedUrl(nextFeedUrl);
}

export function useNextFeedPagination({
  feed,
  nextFeedUrl,
  setNextFeedUrl,
  setIsNextFeedLoading,
  isNextFeedLoading,
  isLoading,
  setFeedEntries,
  feedEntries,
  itemLimit,
  condition,
  isLoadingSkeletonsV2 = true,
  fireOnLoading,
}: {
  feed: Feed | undefined;
  nextFeedUrl: string | undefined;
  setNextFeedUrl: any;
  setIsNextFeedLoading: any;
  isNextFeedLoading: boolean;
  isLoading: boolean | undefined;
  setFeedEntries: any;
  feedEntries: FeedEntry[] | undefined;
  itemLimit: number | undefined;
  condition: boolean;
  isLoadingSkeletonsV2?: boolean;
  fireOnLoading?: Function;
}): void {
  const [failedNextFeedTries, setFailedNextFeedTries] = useState<number>(0);

  useEffect(() => {
    if (isLoading) return;
    nextFeedUrlSetter({
      feed,
      setNextFeedUrl,
    });
  }, [isLoading]);

  useEffect(() => {
    if (failedNextFeedTries !== 0) {
      if (failedNextFeedTries > MAX_EMPTY_NEXT_FEED_TRIES) {
        logger.info('failedNextFeedTries exceeded');
        return;
      }

      nextFeedEffect();

      return;
    }

    if (itemLimit && Number(feed?.entry?.length) >= Number(getLimit(itemLimit)))
      return;

    const initiateNextFeedFetch: boolean =
      !!nextFeedUrl && condition && !isNextFeedLoading;

    if (!initiateNextFeedFetch) return;

    nextFeedEffect();
  }, [condition, feedEntries?.length, failedNextFeedTries]);

  useEffect(() => {
    if (!isLoadingSkeletonsV2 || !isNextFeedLoading) return;

    setFeedEntries((prevFeedEntries: Feed[]) => {
      if (!Array.isArray(prevFeedEntries)) return prevFeedEntries;
      const newFeedEntries = [
        ...prevFeedEntries,
        ...generateSkeletonEntries(NEXT_FEED_SKELETON_ENTRIES),
      ];
      return newFeedEntries;
    });
  }, [isNextFeedLoading]);

  const controller: AbortController = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), NEXT_FEED_TIMEOUT);
  const {
    signal,
  }: {
    signal: AbortSignal;
  } = controller;

  async function nextFeedEffect(): Promise<void> {
    try {
      if (!nextFeedUrl) throw Error('Next feed url is undefined');

      setIsNextFeedLoading(true);

      if (fireOnLoading) fireOnLoading();

      const clientFeedUrl: string = getClientFeedUrl(nextFeedUrl);

      const isAddSlash = clientFeedUrl?.startsWith('/') ? '' : '/';

      const nextFeedResponse = await fetch(`${isAddSlash}${clientFeedUrl}`, {
        signal,
      });

      if (!nextFeedResponse.ok) throw Error('Network response was not ok');

      const nextFeedJsonData: Feed = await nextFeedResponse.json();

      const nextFeedEntries: FeedEntry[] | undefined = nextFeedJsonData?.entry;

      const isEmptyEntries: boolean =
        Array.isArray(nextFeedEntries) && nextFeedEntries?.length === 0;

      const nextFeedAdditionalNext: string | undefined = nextFeedJsonData?.next;

      const isSameUrl: boolean = nextFeedUrl === nextFeedAdditionalNext;

      const isNextFeedAdditionalNext: boolean =
        !isSameUrl && isValidUrl(nextFeedAdditionalNext);

      if (!isEmptyEntries && nextFeedEntries?.length > 0) {
        setFeedEntries((prevFeedEntries: Feed[]) => {
          if (!Array.isArray(nextFeedEntries)) return prevFeedEntries;
          const newFeedEntries = [...prevFeedEntries, ...nextFeedEntries];
          return filterSkeletonEntries(newFeedEntries);
        });
      }

      if (isNextFeedAdditionalNext) {
        nextFeedUrlSetter({
          feed: nextFeedJsonData,
          setNextFeedUrl,
        });
      } else {
        setNextFeedUrl(undefined);
        return;
      }

      if (isEmptyEntries && isNextFeedAdditionalNext) {
        setFailedNextFeedTries((prev: number) => prev + 1);
        return;
      }
    } catch (error: any) {
      setNextFeedUrl(undefined);

      if (error?.name === 'AbortError') {
        logger.info('Next feed fetch timed out');
      }
    } finally {
      setIsNextFeedLoading(false);
      clearTimeout(timeoutId);
    }
  }
}
