import { seoSelectedBreakpoint } from '~/services/layout/layout';
import { type ResponsiveSources } from './power-cell-getters';
import logger from '~/services/logger';

export type TextComponent =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'div';

const textComponentMapping: Record<TextComponent, keyof JSX.IntrinsicElements> =
  {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    p: 'p',
    div: 'div',
  };

/**
 * ResponsiveText component - renders the text content for each breakpoint including SEO content
 * @param responsiveText - Text content for each breakpoint
 * @param componentTextLabelTypography - Text component type (h1, h2, h3, h4, h5, h6, p, div)
 * @returns - JSX.Element with responsive text
 */
export function ResponsiveText({
  responsiveText,
  componentTextLabelTypography = 'p',
}: {
  responsiveText: ResponsiveSources;
  componentTextLabelTypography?: TextComponent;
}): JSX.Element {
  try {
    const seoDefaultText = responsiveText?.[seoSelectedBreakpoint];

    const mobileText = showBreakpointText(responsiveText?.mobile, 'mobile');
    const tabletText = showBreakpointText(responsiveText?.tablet, 'tablet');
    const desktopText = showBreakpointText(responsiveText?.desktop, 'desktop');
    const largeDesktopText = showBreakpointText(
      responsiveText?.['large-desktop'],
      'large-desktop'
    );

    const TextComponent = textComponentMapping[componentTextLabelTypography];

    return (
      <TextComponent
        x-data-mobile={mobileText}
        x-data-tablet={tabletText}
        x-data-desktop={desktopText}
        x-data-large-desktop={largeDesktopText}
        className={`mobile:before:content-[attr(x-data-mobile)] tablet:before:content-[attr(x-data-tablet)] desktop:before:content-[attr(x-data-desktop)] large-desktop:before:content-[attr(x-data-large-desktop)]`}
      >
        <span
          className={`mobile:max-tablet:data-[mobile=NA]:hidden tablet:max-desktop:data-[tablet=NA]:hidden desktop:max-large-desktop:data-[desktop=NA]:hidden large-desktop:data-[large-desktop=NA]:hidden`}
          data-mobile={seoSelectedBreakpoint !== 'mobile' && 'NA'}
          data-tablet={seoSelectedBreakpoint !== 'tablet' && 'NA'}
          data-desktop={seoSelectedBreakpoint !== 'desktop' && 'NA'}
          data-large-desktop={seoSelectedBreakpoint !== 'large-desktop' && 'NA'}
        >
          {seoDefaultText}
        </span>
      </TextComponent>
    );
  } catch (error: any) {
    logger.info(`ResponsiveText: ${error.message}`);
    return <></>;
  }
}

/**
 * Show breakpoint text content if breakpoint is not equal to SEO selected breakpoint
 * @param text - Text content for specific breakpoint
 * @param breakpoint - Breakpoint to check against SEO selected breakpoint
 * @returns - Text content for specific breakpoint if breakpoint is not equal to SEO selected breakpoint
 */
function showBreakpointText(
  text: string | boolean | undefined,
  breakpoint: string
): string {
  if (!text || typeof text === 'boolean') return '';
  return breakpoint === seoSelectedBreakpoint ? '' : text;
}
