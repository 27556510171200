import { getDateTransformManifestFields } from '../../power-cell/components/config-parts/date-transform';

const manifest = {
  plugin_id: 3620,
  identifier: 'cell_2_v2',
  manifest_version: '1.1.9',
  api: {},
  dependency_repository_url: [],
  dependency_name: '',
  author_name: 'Applicaster',
  author_email: 'zapp@applicaster.com',
  name: 'Cell 2 V2',
  description: 'Cell with image alongside text',
  type: 0,
  screen: false,
  react_native: true,
  ui_builder_support: true,
  whitelisted_account_ids: [],
  deprecated_since_zapp_sdk: '',
  unsupported_since_zapp_sdk: '',
  targets: ['web'],
  ui_frameworks: ['quickbrick'],
  platform: 'web',
  dependency_version: '1.0.0',
  min_zapp_sdk: '0.0.1',
  styles: {
    fields: [
      {
        group: true,
        label: 'Cell',
        folded: false,
        fields: [
          // ------- V2 -------
          {
            key: 'cell_border_color',
            label: 'Border color',
            initial_value: 'transparent',
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_hover_border_color',
            label: 'Hover cell border color',
            initial_value: 'transparent',
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_border_width',
            label: 'Border size',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_border_corner_radius',
            label: 'Border corner radius',
            initial_value: 0,
            type: 'number_input',
          },
          // ------- V2 -------
          {
            key: 'cell_background_color',
            label: 'Background color',
            initial_value: 'rgba(0, 0, 0, 0)',
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_background_color_hover',
            label: 'Hover background color',
            initial_value: 'rgba(0, 0, 0, 0)',
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_background_color_selected',
            label: 'Selected background color',
            initial_value: 'rgba(0, 0, 0, 0)',
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_corner_radius',
            label: 'Corner radius',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_padding_top',
            label: 'Padding top',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_padding_right',
            label: 'Padding right',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_padding_bottom',
            label: 'Padding bottom',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_padding_left',
            label: 'Padding left',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_margin_top',
            label: 'Margin top',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_margin_right',
            label: 'Margin right',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_margin_bottom',
            label: 'Margin bottom',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_margin_left',
            label: 'Margin left',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_shadow_color',
            label: 'Shadow color',
            initial_value: 'rgba(0, 0, 0, 0)',
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_shadow_opacity',
            label: 'shadow opacity',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_shadow_offset_width',
            label: 'shadow offset width',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_shadow_offset_height',
            label: 'shadow offset height',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_shadow_radius',
            label: 'shadow radius',
            initial_value: 0,
            type: 'number_input',
          },
        ],
      },
      // #region Cell Borders
      /*
      {
        group: true,
        label: 'Cell Top Border',
        folded: false,
        fields: [
          // WIP ------- V2 ------- cell2 only
          {
            key: 'cell_top_border_switch',
            label: 'enable',
            initial_value: false,
            type: 'switch',
          },
          {
            key: 'cell_top_border_color',
            label: 'Border top color',
            initial_value: 'transparent',
            conditional_fields: [
              { key: 'styles/cell_top_border_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_hover_top_border_color',
            label: 'Hover border top color',
            initial_value: 'transparent',
            conditional_fields: [
              { key: 'styles/cell_top_border_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_selected_top_border_color',
            label: 'Selected border top color',
            initial_value: 'transparent',
            conditional_fields: [
              { key: 'styles/cell_top_border_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_selected_and_hover_top_border_color',
            label: 'Selected & hover border top color',
            initial_value: 'transparent',
            conditional_fields: [
              { key: 'styles/cell_top_border_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_top_border_width',
            label: 'Border top size',
            initial_value: 'transparent',
            conditional_fields: [
              { key: 'styles/cell_top_border_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_top_border_margin_top',
            label: 'Margin top',
            initial_value: '0',
            conditional_fields: [
              { key: 'styles/cell_top_border_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'cell_top_border_margin_right',
            label: 'Margin right',
            initial_value: '0',
            conditional_fields: [
              { key: 'styles/cell_top_border_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'cell_top_border_margin_bottom',
            label: 'Margin bottom',
            initial_value: '0',
            conditional_fields: [
              { key: 'styles/cell_top_border_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'cell_top_border_margin_left',
            label: 'Margin left',
            initial_value: '0',
            conditional_fields: [
              { key: 'styles/cell_top_border_switch', condition_value: true },
            ],
            type: 'number_input',
          },
        ],
      },
      {
        group: true,
        label: 'Cell Bottom Border',
        folded: false,
        fields: [
          // ------- V2 ------- cell2 only
          {
            key: 'cell_bottom_border_switch',
            label: 'enable',
            initial_value: false,
            type: 'switch',
          },
          {
            key: 'cell_bottom_border_color',
            label: 'Border bottom color',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/cell_bottom_border_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_hover_bottom_border_color',
            label: 'Hover border bottom color',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/cell_bottom_border_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_selected_bottom_border_color',
            label: 'Selected border bottom color',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/cell_bottom_border_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_selected_and_hover_bottom_border_color',
            label: 'Selected & hover border bottom color',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/cell_bottom_border_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_bottom_border_width',
            label: 'Border bottom size',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/cell_bottom_border_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_bottom_border_margin_top',
            label: 'Margin top',
            initial_value: '0',
            conditional_fields: [
              {
                key: 'styles/cell_bottom_border_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'cell_bottom_border_margin_right',
            label: 'Margin right',
            initial_value: '0',
            conditional_fields: [
              {
                key: 'styles/cell_bottom_border_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'cell_bottom_border_margin_bottom',
            label: 'Margin bottom',
            initial_value: '0',
            conditional_fields: [
              {
                key: 'styles/cell_bottom_border_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'cell_bottom_border_margin_left',
            label: 'Margin left',
            initial_value: '0',
            conditional_fields: [
              {
                key: 'styles/cell_bottom_border_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
        ],
      },
      */
      // #endregion
      {
        group: true,
        label: 'Runtime label',
        folded: false,
        fields: [
          {
            key: 'runtime_switch',
            label: 'enable',
            initial_value: false,
            type: 'switch',
          },
          {
            key: 'runtime_font_color',
            label: 'Font color',
            rules: 'conditional',
            initial_value: 'rgba(239, 239, 239, 0.65)',
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'runtime_font_family_font_selector',
            label: 'Font Family',
            initial_value: 'Ubuntu-Medium',
            placeholder: 'select font',
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'web_font_selector',
          },
          {
            key: 'runtime_font_size',
            label: 'Font size',
            rules: 'conditional',
            initial_value: 10,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'runtime_line_height',
            label: 'Line height',
            rules: 'conditional',
            initial_value: 14,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'runtime_letter_spacing',
            label: 'Letter spacing',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'runtime_padding_top',
            label: 'Padding top',
            rules: 'conditional',
            initial_value: 4,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'runtime_padding_right',
            label: 'Padding right',
            rules: 'conditional',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'runtime_padding_bottom',
            label: 'Padding bottom',
            rules: 'conditional',
            initial_value: 4,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'runtime_padding_left',
            label: 'Padding left',
            rules: 'conditional',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'runtime_background_color',
            label: 'Background color',
            rules: 'conditional',
            initial_value: 'rgba(17, 17, 17, 0.9)',
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'runtime_corner_radius',
            label: 'Corner radius',
            rules: 'conditional',
            initial_value: 2,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'runtime_margin_right',
            label: 'Margin right',
            rules: 'conditional',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'runtime_margin_bottom',
            label: 'Margin Bottom',
            rules: 'conditional',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
        ],
      },
      {
        group: true,
        label: 'Text Label 1',
        folded: false,
        fields: [
          {
            key: 'text_label_1_switch',
            label: 'Enable',
            initial_value: true,
            type: 'switch',
          },
          {
            key: 'text_label_1_data_key',
            tooltip:
              'What data key from feed entry should be rendered in this field.',
            options: [
              { text: 'summary', value: 'summary' },
              { text: 'title', value: 'title' },
              { text: 'published', value: 'published' },
              { text: 'updated', value: 'updated' },
              { text: 'author.name', value: 'author.name' },
              { text: 'extensions.section', value: 'extensions.section' },
              { text: 'other', value: 'other' },
            ],
            label: 'Data Key',
            initial_value: 'title',
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'text_label_1_custom_data_key',
            rules: 'all_conditions',
            label: 'Custom Data Key',
            initial_value: null,
            conditional_fields: [
              { key: 'styles/text_label_1_data_key', condition_value: 'other' },
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'text_input',
          },
          {
            key: 'text_label_1_typography',
            label: 'Typography',
            options: [
              { text: 'Heading 1', value: 'h1' },
              { text: 'Heading 2', value: 'h2' },
              { text: 'Heading 3', value: 'h3' },
              { text: 'Heading 4', value: 'h4' },
              { text: 'Heading 5', value: 'h5' },
              { text: 'Heading 6', value: 'h6' },
              { text: 'Paragraph', value: 'p' },
              { text: 'Block', value: 'div' },
            ],
            initial_value: 'p',
            type: 'select',
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
          },
          {
            key: 'text_label_1_font_color',
            label: 'Font Color',
            initial_value: 'rgba(255, 255, 255, 1)',
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_1_font_color_hover',
            label: 'Font Color Hover',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_1_font_color_selected',
            label: 'Font Color Selected',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_1_font_family',
            label: 'Font Family',
            initial_value: 'Ubuntu-Medium',
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'web_font_selector',
          },
          {
            key: 'text_label_1_font_size',
            label: 'Font Size',
            initial_value: 16,
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_line_height',
            label: 'Line Height',
            initial_value: 20,
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_letter_spacing',
            label: 'Letter Spacing',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_text_alignment',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Right', value: 'right' },
              { text: 'Center', value: 'center' },
            ],
            label: 'Text Alignment',
            initial_value: 'left',
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'text_label_1_text_transform',
            options: [
              { text: 'Default', value: 'default' },
              { text: 'Uppercase', value: 'uppercase' },
              { text: 'Lowercase', value: 'lowercase' },
              { text: 'Capitalize', value: 'capitalize' },
            ],
            label: 'Text Transform',
            initial_value: 'default',
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'text_label_1_number_of_lines',
            label: 'Number Of Lines',
            initial_value: 1,
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_margin_top',
            label: 'Margin Top',
            initial_value: 8,
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_margin_right',
            label: 'Margin Right',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_margin_bottom',
            label: 'Margin Bottom',
            initial_value: 4,
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_margin_left',
            label: 'Margin Left',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_padding_top',
            label: 'Padding Top',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_padding_right',
            label: 'Padding Right',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_padding_bottom',
            label: 'Padding Bottom',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_padding_left',
            label: 'Padding Left',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_background_color',
            label: 'Background Color',
            initial_value: 'transparent',
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_1_selected_background_color',
            label: 'Selected Background Color',
            initial_value: 'transparent',
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_1_hover_background_color',
            label: 'Hover Background Color',
            initial_value: 'transparent',
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_1_corner_radius',
            label: 'Corner Radius',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_1_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          ...getDateTransformManifestFields('text_label_1'),
        ],
      },
      {
        group: true,
        label: 'Text Label 2',
        folded: false,
        fields: [
          {
            key: 'text_label_2_switch',
            label: 'Enable',
            initial_value: true,
            type: 'switch',
          },
          {
            key: 'text_label_2_data_key',
            tooltip:
              'What data key from feed entry should be rendered in this field.',
            options: [
              { text: 'summary', value: 'summary' },
              { text: 'title', value: 'title' },
              { text: 'published', value: 'published' },
              { text: 'updated', value: 'updated' },
              { text: 'author.name', value: 'author.name' },
              { text: 'extensions.section', value: 'extensions.section' },
              { text: 'other', value: 'other' },
            ],
            label: 'Data Key',
            initial_value: 'summary',
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'text_label_2_custom_data_key',
            rules: 'all_conditions',
            label: 'Custom Data Key',
            initial_value: null,
            conditional_fields: [
              { key: 'styles/text_label_2_data_key', condition_value: 'other' },
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'text_input',
          },
          {
            key: 'text_label_2_typography',
            label: 'Typography',
            options: [
              { text: 'Heading 1', value: 'h1' },
              { text: 'Heading 2', value: 'h2' },
              { text: 'Heading 3', value: 'h3' },
              { text: 'Heading 4', value: 'h4' },
              { text: 'Heading 5', value: 'h5' },
              { text: 'Heading 6', value: 'h6' },
              { text: 'Paragraph', value: 'p' },
              { text: 'Block', value: 'div' },
            ],
            initial_value: 'p',
            type: 'select',
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
          },
          {
            key: 'text_label_2_font_color',
            label: 'Font Color',
            initial_value: 'rgba(239, 239, 239, 0.65)',
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_2_font_color_hover',
            label: 'Font Color Hover',
            initial_value: 'rgba(239, 239, 239, 0.65)',
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_2_font_color_selected',
            label: 'Font Color Selected',
            initial_value: 'rgba(239, 239, 239, 0.65)',
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_2_font_family',
            label: 'Font Family',
            initial_value: 'Ubuntu-Regular',
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'web_font_selector',
          },
          {
            key: 'text_label_2_font_size',
            label: 'Font Size',
            initial_value: 13,
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_line_height',
            label: 'Line Height',
            initial_value: 16,
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_letter_spacing',
            label: 'Letter Spacing',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_text_alignment',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Right', value: 'right' },
              { text: 'Center', value: 'center' },
            ],
            label: 'Text Alignment',
            initial_value: 'left',
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'text_label_2_text_transform',
            options: [
              { text: 'Default', value: 'default' },
              { text: 'Uppercase', value: 'uppercase' },
              { text: 'Lowercase', value: 'lowercase' },
              { text: 'Capitalize', value: 'capitalize' },
            ],
            label: 'Text Transform',
            initial_value: 'default',
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'text_label_2_number_of_lines',
            label: 'Number Of Lines',
            initial_value: 1,
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_margin_top',
            label: 'Margin Top',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_margin_right',
            label: 'Margin Right',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_margin_bottom',
            label: 'Margin Bottom',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_margin_left',
            label: 'Margin Left',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_padding_top',
            label: 'Padding Top',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_padding_right',
            label: 'Padding Right',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_padding_bottom',
            label: 'Padding Bottom',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_padding_left',
            label: 'Padding Left',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_background_color',
            label: 'Background Color',
            initial_value: 'transparent',
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_2_hover_background_color',
            label: 'Hover Background Color',
            initial_value: 'transparent',
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_2_selected_background_color',
            label: 'Selected Background Color',
            initial_value: 'transparent',
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_2_corner_radius',
            label: 'Corner Radius',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_2_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          ...getDateTransformManifestFields('text_label_2'),
        ],
      },
      {
        group: true,
        label: 'Text Label 3',
        folded: true,
        fields: [
          {
            key: 'text_label_3_switch',
            label: 'Enable',
            initial_value: false,
            type: 'switch',
          },
          {
            key: 'text_label_3_data_key',
            tooltip:
              'What data key from feed entry should be rendered in this field.',
            options: [
              { text: 'summary', value: 'summary' },
              { text: 'title', value: 'title' },
              { text: 'published', value: 'published' },
              { text: 'updated', value: 'updated' },
              { text: 'author.name', value: 'author.name' },
              { text: 'extensions.section', value: 'extensions.section' },
              { text: 'other', value: 'other' },
            ],
            label: 'Data Key',
            initial_value: 'summary',
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'text_label_3_custom_data_key',
            rules: 'all_conditions',
            label: 'Custom Data Key',
            initial_value: null,
            conditional_fields: [
              { key: 'styles/text_label_3_data_key', condition_value: 'other' },
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'text_input',
          },
          {
            key: 'text_label_3_typography',
            label: 'Typography',
            options: [
              { text: 'Heading 1', value: 'h1' },
              { text: 'Heading 2', value: 'h2' },
              { text: 'Heading 3', value: 'h3' },
              { text: 'Heading 4', value: 'h4' },
              { text: 'Heading 5', value: 'h5' },
              { text: 'Heading 6', value: 'h6' },
              { text: 'Paragraph', value: 'p' },
              { text: 'Block', value: 'div' },
            ],
            initial_value: 'p',
            type: 'select',
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
          },
          {
            key: 'text_label_3_font_color',
            label: 'Font Color',
            initial_value: 'rgba(239, 239, 239, 0.65)',
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_3_font_color_hover',
            label: 'Font Color Hover',
            initial_value: 'rgba(239, 239, 239, 0.65)',
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_3_font_color_selected',
            label: 'Font Color Selected',
            initial_value: 'rgba(239, 239, 239, 0.65)',
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_3_font_family',
            label: 'Font Family',
            initial_value: 'Ubuntu-Regular',
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'web_font_selector',
          },
          {
            key: 'text_label_3_font_size',
            label: 'Font Size',
            initial_value: 13,
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_line_height',
            label: 'Line Height',
            initial_value: 16,
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_letter_spacing',
            label: 'Letter Spacing',
            initial_value: -0.2,
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_text_alignment',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Right', value: 'right' },
              { text: 'Center', value: 'center' },
            ],
            label: 'Text Alignment',
            initial_value: 'left',
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'text_label_3_text_transform',
            options: [
              { text: 'Default', value: 'default' },
              { text: 'Uppercase', value: 'uppercase' },
              { text: 'Lowercase', value: 'lowercase' },
              { text: 'Capitalize', value: 'capitalize' },
            ],
            label: 'Text Transform',
            initial_value: 'default',
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'text_label_3_number_of_lines',
            label: 'Number Of Lines',
            initial_value: 1,
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_margin_top',
            label: 'Margin Top',
            initial_value: 4,
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_margin_right',
            label: 'Margin Right',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_margin_bottom',
            label: 'Margin Bottom',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_margin_left',
            label: 'Margin Left',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_padding_top',
            label: 'Padding Top',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_padding_right',
            label: 'Padding Right',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_padding_bottom',
            label: 'Padding Bottom',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_padding_left',
            label: 'Padding Left',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_background_color',
            label: 'Background Color',
            initial_value: 'transparent',
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_3_hover_background_color',
            label: 'Hover Background Color',
            initial_value: 'transparent',
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_3_selected_background_color',
            label: 'Selected Background Color',
            initial_value: 'transparent',
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_3_corner_radius',
            label: 'Corner Radius',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_3_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          ...getDateTransformManifestFields('text_label_3'),
        ],
      },
      {
        group: true,
        label: 'Text Label 4',
        folded: true,
        fields: [
          {
            key: 'text_label_4_switch',
            label: 'Enable',
            initial_value: false,
            type: 'switch',
          },
          {
            key: 'text_label_4_data_key',
            tooltip:
              'What data key from feed entry should be rendered in this field.',
            options: [
              { text: 'summary', value: 'summary' },
              { text: 'title', value: 'title' },
              { text: 'published', value: 'published' },
              { text: 'updated', value: 'updated' },
              { text: 'author.name', value: 'author.name' },
              { text: 'extensions.section', value: 'extensions.section' },
              { text: 'other', value: 'other' },
            ],
            label: 'Data Key',
            initial_value: 'summary',
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'text_label_4_custom_data_key',
            rules: 'all_conditions',
            label: 'Custom Data Key',
            initial_value: null,
            conditional_fields: [
              { key: 'styles/text_label_4_data_key', condition_value: 'other' },
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'text_input',
          },
          {
            key: 'text_label_4_typography',
            label: 'Typography',
            options: [
              { text: 'Heading 1', value: 'h1' },
              { text: 'Heading 2', value: 'h2' },
              { text: 'Heading 3', value: 'h3' },
              { text: 'Heading 4', value: 'h4' },
              { text: 'Heading 5', value: 'h5' },
              { text: 'Heading 6', value: 'h6' },
              { text: 'Paragraph', value: 'p' },
              { text: 'Block', value: 'div' },
            ],
            initial_value: 'p',
            type: 'select',
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
          },
          {
            key: 'text_label_4_font_color',
            label: 'Font Color',
            initial_value: 'rgba239, 239, 239, 0.65)',
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_4_font_color_hover',
            label: 'Font Color Hover',
            initial_value: 'rgba239, 239, 239, 0.65)',
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_4_font_color_selected',
            label: 'Font Color Selected',
            initial_value: 'rgba(239, 239, 239, 0.65)',
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_4_font_family',
            label: 'Font Family',
            initial_value: 'Ubuntu-Regular',
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'web_font_selector',
          },
          {
            key: 'text_label_4_font_size',
            label: 'Font Size',
            initial_value: 13,
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_4_line_height',
            label: 'Line Height',
            initial_value: 16,
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_4_letter_spacing',
            label: 'Letter Spacing',
            initial_value: -0.2,
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_4_text_alignment',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Right', value: 'right' },
              { text: 'Center', value: 'center' },
            ],
            label: 'Text Alignment',
            initial_value: 'left',
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'text_label_4_text_transform',
            options: [
              { text: 'Default', value: 'default' },
              { text: 'Uppercase', value: 'uppercase' },
              { text: 'Lowercase', value: 'lowercase' },
              { text: 'Capitalize', value: 'capitalize' },
            ],
            label: 'Text Transform',
            initial_value: 'default',
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'text_label_4_number_of_lines',
            label: 'Number Of Lines',
            initial_value: 1,
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_4_margin_top',
            label: 'Margin Top',
            initial_value: 4,
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_4_margin_right',
            label: 'Margin Right',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_4_margin_bottom',
            label: 'Margin Bottom',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_4_margin_left',
            label: 'Margin Left',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_4_padding_top',
            label: 'Padding Top',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_4_padding_right',
            label: 'Padding Right',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_4_padding_bottom',
            label: 'Padding Bottom',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_4_padding_left',
            label: 'Padding Left',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_4_background_color',
            label: 'Background Color',
            initial_value: 'transparent',
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_4_hover_background_color',
            label: 'Hover Background Color',
            initial_value: 'transparent',
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_4_selected_background_color',
            label: 'Selected Background Color',
            initial_value: 'transparent',
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_4_corner_radius',
            label: 'Corner Radius',
            initial_value: 0,
            conditional_fields: [
              { key: 'styles/text_label_4_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          ...getDateTransformManifestFields('text_label_4'),
        ],
      },
    ],
  },
  assets: {
    fields: [
      // #region Nested image
      /*
      {
        group: true,
        label: 'Nested image',
        folded: false,
        fields: [
          {
            key: 'nested_image_switch',
            label: 'enable',
            initial_value: false,
            type: 'switch',
          },
          {
            key: 'nested_image_key',
            label: 'Image key',
            rules: 'conditional',
            initial_value: 'thumb_1',
            conditional_fields: [
              {
                key: 'assets/nested_image_switch',
                condition_value: true,
              },
            ],
            type: 'text_input',
          },
          {
            key: 'nested_image_height',
            label: 'Height',
            rules: 'conditional',
            initial_value: 72,
            conditional_fields: [
              {
                key: 'assets/nested_image_display_mode',
                condition_value: 'fixed',
              },
              {
                key: 'assets/nested_image_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'nested_image_width',
            label: 'Width',
            rules: 'conditional',
            initial_value: 128,
            conditional_fields: [
              {
                key: 'assets/nested_image_display_mode',
                condition_value: 'fixed',
              },
              {
                key: 'assets/nested_image_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'nested_image_sizing',
            label: 'Image sizing',
            options: [
              {
                text: 'Fit',
                value: 'fit',
              },
              {
                text: 'Fill',
                value: 'fill',
              },
            ],
            rules: 'conditional',
            initial_value: 'fit',
            conditional_fields: [
              {
                key: 'assets/nested_image_display_mode',
                condition_value: 'fixed',
              },
              {
                key: 'assets/nested_image_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'nested_image_fit_position',
            label: 'Fit position',
            options: [
              {
                text: 'Center',
                value: 'center',
              },
              {
                text: 'Center Left',
                value: 'center_left',
              },
              {
                text: 'Center Right',
                value: 'center_right',
              },
              {
                text: 'Bottom Center',
                value: 'bottom_center',
              },
              {
                text: 'Bottom Left',
                value: 'bottom_left',
              },
              {
                text: 'Bottom Right',
                value: 'bottom_right',
              },
              {
                text: 'Top Center',
                value: 'top_center',
              },
              {
                text: 'Top Left',
                value: 'top_left',
              },
              {
                text: 'Top Right',
                value: 'top_right',
              },
            ],
            rules: 'conditional',
            initial_value: 'center',
            conditional_fields: [
              {
                key: 'assets/nested_image_sizing',
                condition_value: 'fit',
              },
              {
                key: 'assets/nested_image_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'nested_image_position',
            label: 'Position',
            options: [
              {
                text: 'Over image',
                value: 'over_image',
              },
              {
                text: 'Above text label 1',
                value: 'above_text_label_1',
              },
              {
                text: 'Below text label 1',
                value: 'below_text_label_1',
              },
              {
                text: 'Above text label 2',
                value: 'above_text_label_2',
              },
              {
                text: 'Below text label 2',
                value: 'below_text_label_2',
              },
              {
                text: 'Above text label 3',
                value: 'above_text_label_3',
              },
              {
                text: 'Below text label 3',
                value: 'below_text_label_3',
              },
              {
                text: 'Above text label 4',
                value: 'above_text_label_4',
              },
              {
                text: 'Below text label 4',
                value: 'below_text_label_4',
              },
            ],
            rules: 'conditional',
            initial_value: 'above_text_label_1',
            conditional_fields: [
              {
                key: 'assets/nested_image_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'nested_image_align',
            label: 'Align',
            options: [
              {
                text: 'Left',
                value: 'left',
              },
              {
                text: 'Center',
                value: 'center',
              },
              {
                text: 'Right',
                value: 'right',
              },
            ],
            rules: 'conditional',
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'assets/nested_image_position',
                condition_value: 'over_image',
              },
              {
                key: 'assets/nested_image_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'nested_image_image_position',
            label: 'Image position',
            options: [
              {
                text: 'Center',
                value: 'center',
              },
              {
                text: 'Top Left',
                value: 'top_left',
              },
              {
                text: 'Top Right',
                value: 'top_right',
              },
              {
                text: 'Bottom Left',
                value: 'bottom_left',
              },
              {
                text: 'Bottom Right',
                value: 'bottom_right',
              },
            ],
            rules: 'conditional',
            initial_value: 'bottom_left',
            conditional_fields: [
              {
                key: 'assets/nested_image_position',
                condition_value: 'over_image',
              },
              {
                key: 'assets/nested_image_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'nested_image_corner_radius',
            label: 'Corner radius',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/nested_image_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'nested_image_margin_top',
            label: 'Margin top',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/nested_image_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'nested_image_margin_right',
            label: 'Margin right',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/nested_image_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'nested_image_margin_bottom',
            label: 'Margin bottom',
            initial_value: 4,
            conditional_fields: [
              {
                key: 'assets/nested_image_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'nested_image_margin_left',
            label: 'Margin left',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/nested_image_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
        ],
      },
      */
      // #endregion
      {
        group: true,
        label: 'Badges',
        folded: false,
        fields: [
          {
            key: 'content_badge_switch',
            label: 'Enable',
            initial_value: false,
            type: 'switch',
          },
          {
            // ------ V2 ------
            key: 'content_badge_hover_switch',
            label: 'Enable hover asset',
            initial_value: false,
            type: 'switch',
          },
          {
            key: 'static_badge',
            label: 'Static badge',
            rules: 'conditional',
            initial_value: '/play_icon.png',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'static_badge_hover',
            label: 'Hover static badge',
            rules: 'conditional',
            initial_value: '/play_icon.png',
            conditional_fields: [
              {
                key: 'assets/content_badge_hover_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'program_badge',
            label: 'Program badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'program_badge_hover',
            label: 'Hover program badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_hover_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'feed_badge',
            label: 'Feed badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'feed_badge_hover',
            label: 'Hover feed badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_hover_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'link_badge',
            label: 'Link badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'link_badge_hover',
            label: 'Hover link badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_hover_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'article_badge',
            label: 'Article badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'article_badge_hover',
            label: 'Hover article badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_hover_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'channel_badge',
            label: 'Channel badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'channel_badge_hover',
            label: 'Hover channel badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_hover_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'video_badge',
            label: 'Video badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'video_badge_hover',
            label: 'Hover video badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_hover_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'content_type_custom_badge_1',
            label: 'Custom content type 1',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'text_input',
          },
          {
            key: 'custom_badge_1',
            label: 'Custom badge 1',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'custom_badge_1_hover',
            label: 'Hover custom badge 1',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_hover_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },

          {
            key: 'content_type_custom_badge_2',
            label: 'Custom content type 2',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'text_input',
          },
          {
            key: 'custom_badge_2',
            label: 'Custom badge 2',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'custom_badge_2_hover',
            label: 'Hover custom badge 2',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_hover_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'content_type_custom_badge_3',
            label: 'Custom content type 3',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'text_input',
          },
          {
            key: 'custom_badge_3',
            label: 'Custom badge 3',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'custom_badge_3_hover',
            label: 'Hover custom badge 3',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_hover_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'content_type_custom_badge_4',
            label: 'Custom content type 4',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'text_input',
          },
          {
            key: 'custom_badge_4',
            label: 'Custom badge 4',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'custom_badge_4_hover',
            label: 'Hover custom badge 4',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_hover_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'content_type_custom_badge_5',
            label: 'Custom content type 5',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'text_input',
          },
          {
            key: 'custom_badge_5',
            label: 'Custom badge 5',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'custom_badge_5_hover',
            label: 'Hover custom badge 5',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_hover_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'content_badge_width',
            label: 'Width',
            initial_value: '44',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'content_badge_height',
            label: 'Height',
            initial_value: '44',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'content_badge_margin_top',
            label: 'Margin top',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'content_badge_margin_right',
            label: 'Margin right',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'content_badge_margin_bottom',
            label: 'Margin bottom',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'content_badge_margin_left',
            label: 'Margin left',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
        ],
      },
      {
        group: true,
        label: 'Lock badge',
        folded: false,
        fields: [
          {
            key: 'lock_badge_switch',
            label: 'enable',
            initial_value: false,
            type: 'switch',
          },
          {
            key: 'locked_badge',
            label: 'Locked badge',
            label_tooltip:
              'The lock badge will show when the data key is false',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              { key: 'assets/lock_badge_switch', condition_value: true },
            ],
            type: 'uploader',
          },
          {
            key: 'unlocked_badge',
            label: 'Unlocked badge',
            label_tooltip:
              'The unlock badge will show when the data key is true',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              { key: 'assets/lock_badge_switch', condition_value: true },
            ],
            type: 'uploader',
          },
          {
            key: 'lock_badge_position',
            label: 'Locked badge position',
            options: [
              { text: 'Center', value: 'center' },
              { text: 'Top Left', value: 'top_left' },
              { text: 'Top Right', value: 'top_right' },
              { text: 'Bottom Left', value: 'bottom_left' },
              { text: 'Bottom Right', value: 'bottom_right' },
            ],
            initial_value: 'top_left',
            rules: 'conditional',
            conditional_fields: [
              { key: 'assets/lock_badge_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'lock_badge_data_key',
            initial_value: 'extensions.free',
            label: 'Lock badge data key',
            placeholder: 'extensions.free',
            label_tooltip:
              'Specify path to key that will determine whether the\n               content should be locked or not. i.e.extensions.isFree, if true unlock.\n               If false or the key does not exist in the entry, lock badge will show',
            rules: 'conditional',
            conditional_fields: [
              { key: 'assets/lock_badge_switch', condition_value: true },
            ],
            type: 'text_input',
          },
          {
            key: 'lock_badge_width',
            label: 'Width',
            initial_value: '44',
            conditional_fields: [
              { key: 'assets/lock_badge_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'lock_badge_height',
            label: 'Height',
            initial_value: '44',
            conditional_fields: [
              { key: 'assets/lock_badge_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'lock_badge_margin_top',
            label: 'Margin top',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/lock_badge_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'lock_badge_margin_right',
            label: 'Margin right',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/lock_badge_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'lock_badge_margin_bottom',
            label: 'Margin bottom',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/lock_badge_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'lock_badge_margin_left',
            label: 'Margin left',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/lock_badge_switch', condition_value: true },
            ],
            type: 'number_input',
          },
        ],
      },
      {
        group: true,
        label: 'Action Button',
        folded: false,
        fields: [
          {
            key: 'action_switch',
            label: 'enable',
            initial_value: false,
            type: 'switch',
          },
          {
            key: 'action_identifier',
            label: 'Action plugin identifier',
            initial_value: 'local_storage_favourites_action',
            placeholder: 'plugin_identifier',
            label_tooltip:
              "Action button requires an action plugin, please provide the plugin's id",
            rules: 'conditional',
            conditional_fields: [
              { key: 'assets/action_switch', condition_value: true },
            ],
            type: 'text_input',
          },
          {
            key: 'action_asset_flavour',
            label: 'Action asset flavour',
            label_tooltip:
              'Some actions offer two flavours of assets in their configuration so the button appears nicely over different styles of background. This option lets you select the flavour you want to use',
            options: [
              { text: 'Flavour 1', value: 'flavour_1' },
              { text: 'Flavour 2', value: 'flavour_2' },
            ],
            initial_value: 'flavour_1',
            conditional_fields: [
              { key: 'assets/action_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'action_position',
            label: 'Action position',
            options: [
              { text: 'Center', value: 'center' },
              { text: 'Top Left', value: 'top_left' },
              { text: 'Top Right', value: 'top_right' },
              { text: 'Bottom Left', value: 'bottom_left' },
              { text: 'Bottom Right', value: 'bottom_right' },
              { text: 'Cell Right', value: 'cell_right' },
            ],
            initial_value: 'top_right',
            rules: 'conditional',
            conditional_fields: [
              { key: 'assets/action_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'action_width',
            label: 'Width',
            initial_value: 44,
            conditional_fields: [
              { key: 'assets/action_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'action_height',
            label: 'Height',
            initial_value: 44,
            conditional_fields: [
              { key: 'assets/action_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'action_margin_top',
            label: 'Margin top',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/action_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'action_margin_right',
            label: 'Margin right',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/action_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'action_margin_bottom',
            label: 'Margin bottom',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/action_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'action_margin_left',
            label: 'Margin left',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/action_switch', condition_value: true },
            ],
            type: 'number_input',
          },
        ],
      },
      {
        group: true,
        label: 'Image',
        folded: false,
        fields: [
          {
            key: 'image_switch',
            label: 'enable',
            initial_value: true,
            type: 'switch',
          },
          {
            key: 'image_key',
            label: 'Image key',
            initial_value: 'image_base',
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
            type: 'text_input',
          },
          {
            key: 'image_aspect_ratio',
            label: 'Content image ratio',
            label_tooltip:
              'Chose the aspect ratio for your images, 16x9 is default',
            options: [
              { value: '1x1', text: '1x1' },
              { value: '2x3', text: '2x3' },
              { value: '3x2', text: '3x2' },
              { value: '3x4', text: '3x4' },
              { value: '4x3', text: '4x3' },
              { value: '16x9', text: '16x9' },
              { value: 'other', text: 'other' },
            ],
            initial_value: '16x9',
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'image_aspect_ratio_custom',
            label: 'Custom aspect ratio',
            placeholder: '16x10',
            label_tooltip:
              "Enter a custom aspect ratio for the cell image like - '16x10'",
            conditional_fields: [
              { key: 'assets/image_aspect_ratio', condition_value: 'other' },
            ],
            type: 'text_input',
          },
          {
            key: 'image_placeholder_color',
            label: 'Placeholder color',
            initial_value: 'rgba(36, 41, 54, 1)',
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'image_placeholder',
            label: 'Placeholder image',
            initial_value: '',
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
            type: 'uploader',
          },
          {
            key: 'image_border_color',
            label: 'Image Border color',
            initial_value: 'transparent',
            type: 'color_picker_rgba',
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
          },
          {
            key: 'image_hover_border_color',
            label: 'Hover border color',
            initial_value: 'rgba(239, 239, 239, 1)',
            type: 'color_picker_rgba',
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
          },
          {
            key: 'image_border_width',
            label: 'Border size',
            initial_value: 3,
            type: 'number_input',
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
          },
          {
            key: 'image_border_radius',
            label: 'Border corner radius',
            initial_value: 6,
            type: 'number_input',
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
          },
          {
            key: 'image_overlay',
            label: 'Overlay image',
            initial_value: '',
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
            type: 'uploader',
          },
          {
            key: 'image_width',
            label: 'Width',
            initial_value: '142',
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'image_corner_radius',
            label: 'Corner radius',
            initial_value: 4,
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'image_margin_top',
            label: 'Margin top',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'image_margin_right',
            label: 'Margin right',
            initial_value: 16,
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'image_margin_bottom',
            label: 'Margin bottom',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'image_margin_left',
            label: 'Margin left',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'image_shadow_color',
            label: 'Shadow color',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'image_shadow_opacity',
            label: 'shadow opacity',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'image_shadow_offset_width',
            label: 'shadow offset width',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'image_shadow_offset_height',
            label: 'shadow offset height',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'image_shadow_radius',
            label: 'shadow radius',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/image_switch', condition_value: true },
            ],
            type: 'number_input',
          },
        ],
      },
      {
        group: true,
        label: 'Cell Badging',
        folded: false,
        fields: [
          {
            key: 'cell_badge_switch',
            label: 'enable',
            initial_value: true,
            type: 'switch',
          },
          {
            key: 'cell_badge',
            label: 'Cell badge',
            rules: 'conditional',
            initial_value:
              'https://assets-production.applicaster.com/applicaster-employees/zapp_team/assets/cells/arrow_icon.png',
            conditional_fields: [
              { key: 'assets/cell_badge_switch', condition_value: true },
            ],
            type: 'uploader',
          },
          {
            key: 'selected_cell_badge',
            label: 'Selected Cell badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              { key: 'assets/cell_badge_switch', condition_value: true },
            ],
            type: 'uploader',
          },
          {
            key: 'cell_badge_width',
            label: 'Width',
            initial_value: 24,
            conditional_fields: [
              { key: 'assets/cell_badge_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'cell_badge_height',
            label: 'Height',
            initial_value: 24,
            conditional_fields: [
              { key: 'assets/cell_badge_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'cell_badge_margin_left',
            label: 'Margin left',
            rules: 'conditional',
            initial_value: 16,
            conditional_fields: [
              { key: 'assets/cell_badge_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'cell_badge_margin_right',
            label: 'Margin right',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/cell_badge_switch', condition_value: true },
            ],
            type: 'number_input',
          },
        ],
      },
      {
        group: true,
        label: 'Progress Bar',
        folded: false,
        fields: [
          {
            key: 'progress_bar_switch',
            label: 'Enable',
            initial_value: false,
            type: 'switch',
          },
          {
            key: 'progress_bar_hide_unwatched',
            rules: 'all_conditions',
            label: 'Hide Unwatched',
            initial_value: true,
            conditional_fields: [
              { key: 'assets/progress_bar_switch', condition_value: true },
            ],
            type: 'switch',
          },
          {
            key: 'progress_bar_total_bar_color',
            rules: 'all_conditions',
            label: 'Total Bar Color',
            initial_value: 'rgba(255, 255, 255, 0.25)',
            conditional_fields: [
              { key: 'assets/progress_bar_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'progress_bar_total_bar_corner_radius',
            rules: 'all_conditions',
            label: 'Total Bar Corner Radius',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/progress_bar_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'progress_bar_progress_bar_color',
            rules: 'all_conditions',
            label: 'Progress Bar Color',
            initial_value: 'rgba(255, 255, 255, 1)',
            conditional_fields: [
              { key: 'assets/progress_bar_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'progress_bar_progress_bar_corner_radius',
            rules: 'all_conditions',
            label: 'Progress Bar Corner Radius',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/progress_bar_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'progress_bar_height',
            rules: 'all_conditions',
            label: 'Height',
            initial_value: 5,
            conditional_fields: [
              { key: 'assets/progress_bar_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'progress_bar_margin_top',
            rules: 'all_conditions',
            label: 'Margin Top',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/progress_bar_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'progress_bar_margin_right',
            rules: 'all_conditions',
            label: 'Margin Right',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/progress_bar_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'progress_bar_margin_bottom',
            rules: 'all_conditions',
            label: 'Margin Bottom',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/progress_bar_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'progress_bar_margin_left',
            rules: 'all_conditions',
            label: 'Margin Left',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/progress_bar_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'progress_bar_position',
            options: [
              { text: 'On Top of Image', value: 'on_top_of_image' },
              { text: 'Bottom of cell', value: 'bottom_of_cell' },
              { text: 'Above Text Label 1', value: 'above_text_label_1' },
              { text: 'Below Text Label 1', value: 'below_text_label_1' },
              { text: 'Above Text Label 2', value: 'above_text_label_2' },
              { text: 'Below Text Label 2', value: 'below_text_label_2' },
              { text: 'Above Text Label 3', value: 'above_text_label_3' },
              { text: 'Below Text Label 3', value: 'below_text_label_3' },
              { text: 'Above Text Label 4', value: 'above_text_label_4' },
              { text: 'Below Text Label 4', value: 'below_text_label_4' },
            ],
            rules: 'all_conditions',
            label: 'Position',
            initial_value: 'on_top_of_image',
            conditional_fields: [
              { key: 'assets/progress_bar_switch', condition_value: true },
            ],
            type: 'select',
          },
        ],
      },

      // #region Buttons Container
      {
        group: true,
        label: 'Buttons Container',
        folded: false,
        fields: [
          //   ------ V2 ------
          {
            key: 'buttons_container_switch',
            label: 'enable',
            initial_value: false,
            type: 'switch',
          },
          // {
          //   key: 'buttons_container_independent_styles',
          //   label: 'Independent styles',
          //   initial_value: false,
          //   type: 'switch',
          //   conditional_fields: [
          //     {
          //       key: 'assets/buttons_container_switch',
          //       condition_value: true,
          //     },
          //   ],
          // },
          {
            key: 'buttons_container_position',
            label: 'Position',
            options: [
              {
                text: 'Above text label 1',
                value: 'above_text_label_1',
              },
              {
                text: 'Below text label 1',
                value: 'below_text_label_1',
              },
              {
                text: 'Above text label 2',
                value: 'above_text_label_2',
              },
              {
                text: 'Below text label 2',
                value: 'below_text_label_2',
              },
              {
                text: 'Above text label 3',
                value: 'above_text_label_3',
              },
              {
                text: 'Below text label 3',
                value: 'below_text_label_3',
              },
              {
                text: 'Above text label 4',
                value: 'above_text_label_4',
              },
              {
                text: 'Below text label 4',
                value: 'below_text_label_4',
              },
            ],
            initial_value: 'below_text_label_1',
            conditional_fields: [
              {
                key: 'assets/buttons_container_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'buttons_container_align',
            label: 'Align',
            options: [
              {
                text: 'Left',
                value: 'left',
              },
              {
                text: 'Center',
                value: 'center',
              },
              {
                text: 'Right',
                value: 'right',
              },
            ],
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'assets/buttons_container_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'buttons_container_stacking',
            label: 'Stacking',
            options: [
              {
                text: 'Horizontal',
                value: 'horizontal',
              },
              {
                text: 'Vertical',
                value: 'vertical',
              },
            ],
            initial_value: 'horizontal',
            conditional_fields: [
              {
                key: 'assets/buttons_container_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'buttons_container_margin_top',
            label: 'Margin top',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/buttons_container_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'buttons_container_margin_right',
            label: 'Margin right',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/buttons_container_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'buttons_container_margin_bottom',
            label: 'Margin bottom',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/buttons_container_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'buttons_container_margin_left',
            label: 'Margin left',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/buttons_container_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'buttons_container_horizontal_gutter',
            label: 'Horizontal gutter',
            initial_value: 12,
            conditional_fields: [
              {
                key: 'assets/buttons_container_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'buttons_container_vertical_gutter',
            label: 'Vertical gutter',
            initial_value: 12,
            conditional_fields: [
              {
                key: 'assets/buttons_container_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
        ],
      },
      // #region Button 1
      {
        group: true,
        label: 'Button 1',
        folded: false,
        fields: [
          //   ------ V2 ------
          {
            key: 'button1_switch',
            label: 'enable',
            initial_value: true,
            type: 'switch',
          },
          {
            key: 'button1_assign_action',
            label: 'Assign Action',
            options: [
              {
                text: 'Play',
                value: 'play',
              },
              {
                text: 'Favorite',
                value: 'favorite',
              },
              {
                text: 'Trailer',
                value: 'trailer',
              },
            ],
            initial_value: 'play',
            type: 'select',
          },
          {
            key: 'button1_display_mode',
            label: 'Display mode',
            options: [
              {
                text: 'Dynamic',
                value: 'dynamic',
              },
              {
                text: 'Fixed',
                value: 'fixed',
              },
              {
                text: 'Fill',
                value: 'fill',
              },
            ],
            initial_value: 'dynamic',
            type: 'select',
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'button1_width',
            label: 'Width',
            initial_value: 120,
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
            label_tooltip: 'Width: Applies to Fixed display mode',
          },
          {
            key: 'button1_content_alignment',
            label: 'Button Content Alignment',
            type: 'select',
            options: [
              {
                text: 'Left',
                value: 'left',
              },
              {
                text: 'Center',
                value: 'center',
              },
              {
                text: 'Right',
                value: 'right',
              },
            ],
            initial_value: 'center',
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            label_tooltip:
              'Button content alignment: Applies to Fixed and Fill display modes',
          },
          {
            key: 'button1_background_color',
            label: 'Background color',
            initial_value: 'rgba(239, 239, 239, 0.25)',
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button1_hover_background_color',
            label: 'Hover background color',
            initial_value: 'rgba(254, 20, 72, 1)',
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button1_border_color',
            label: 'Border color',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button1_hover_border_color',
            label: 'Hover border color',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button1_border_size',
            label: 'Border size',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button1_corner_radius',
            label: 'Corner radius',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button1_padding_top',
            label: 'Padding top',
            initial_value: 12,
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button1_padding_right',
            label: 'Padding right',
            initial_value: 20,
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button1_padding_bottom',
            label: 'Padding bottom',
            initial_value: 12,
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button1_padding_left',
            label: 'Padding left',
            initial_value: 12,
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button1_enable_asset',
            label: 'Enable asset',
            initial_value: true,
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'switch',
          },
          {
            key: 'button1_asset_alignment',
            label: 'Asset alignment',
            options: [
              {
                text: 'Left',
                value: 'left',
              },
              {
                text: 'Right',
                value: 'right',
              },
            ],
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'assets/button1_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button1_asset_height',
            label: 'Asset height',
            initial_value: 24,
            conditional_fields: [
              {
                key: 'assets/button1_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button1_asset_width',
            label: 'Asset width',
            initial_value: 24,
            conditional_fields: [
              {
                key: 'assets/button1_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button1_asset_margin_top',
            label: 'Asset margin top',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button1_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button1_asset_margin_right',
            label: 'Asset margin right',
            initial_value: 4,
            conditional_fields: [
              {
                key: 'assets/button1_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button1_asset_margin_bottom',
            label: 'Asset margin bottom',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button1_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button1_asset_margin_left',
            label: 'Asset margin left',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button1_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button1_enable_label_1',
            label: 'Enable label 1',
            initial_value: true,
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'switch',
          },
          {
            key: 'button1_font_color',
            label: 'Font color',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'assets/button1_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button1_hover_font_color',
            label: 'Hover font color',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'assets/button1_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button1_font_family',
            label: 'Font family',
            initial_value: 'Ubuntu-Medium',
            conditional_fields: [
              {
                key: 'assets/button1_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'web_font_selector',
          },
          {
            key: 'button1_font_size',
            label: 'Font size',
            initial_value: 14,
            conditional_fields: [
              {
                key: 'assets/button1_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button1_line_height',
            label: 'Line height',
            initial_value: 24,
            conditional_fields: [
              {
                key: 'assets/button1_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button1_letter_spacing',
            label: 'Letter spacing',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button1_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          // {
          //   key: 'button1_text_alignment',
          //   label: 'Text alignment',
          //   options: [
          //     {
          //       text: 'Left',
          //       value: 'left',
          //     },
          //     {
          //       text: 'Center',
          //       value: 'center',
          //     },
          //     {
          //       text: 'Right',
          //       value: 'right',
          //     },
          //   ],
          //   initial_value: 'left',
          //   conditional_fields: [
          //     {
          //       key: 'assets/button1_enable_label_1',
          //       condition_value: true,
          //     },
          //     {
          //       key: 'assets/button1_switch',
          //       condition_value: true,
          //     },
          //   ],
          //   type: 'select',
          // },
          {
            key: 'button1_text_transform',
            label: 'Text transform',
            options: [
              {
                text: 'Default',
                value: 'default',
              },
              {
                text: 'Uppercase',
                value: 'uppercase',
              },
              {
                text: 'Lowercase',
                value: 'lowercase',
              },
              {
                text: 'Capitalize',
                value: 'capitalize',
              },
            ],
            initial_value: 'default',
            conditional_fields: [
              {
                key: 'assets/button1_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button1_default_text_decoration',
            label: 'Default text decoration',
            options: [
              {
                text: 'None',
                value: 'none',
              },
              {
                text: 'Underline',
                value: 'underline',
              },
              {
                text: 'Strikethrough',
                value: 'line-through',
              },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'assets/button1_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button1_hover_text_decoration',
            label: 'Hover text decoration',
            options: [
              {
                text: 'None',
                value: 'none',
              },
              {
                text: 'Underline',
                value: 'underline',
              },
              {
                text: 'Strikethrough',
                value: 'line-through',
              },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'assets/button1_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          // {
          //   key: 'button1_number_of_lines',
          //   label: 'Number of lines',
          //   initial_value: 1,
          //   conditional_fields: [
          //     {
          //       key: 'assets/button1_enable_label_1',
          //       condition_value: true,
          //     },
          //     {
          //       key: 'assets/button1_switch',
          //       condition_value: true,
          //     },
          //   ],
          //   type: 'number_input',
          // },
          {
            key: 'button1_margin_top',
            label: 'Margin top',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button1_margin_right',
            label: 'Margin right',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button1_margin_bottom',
            label: 'Margin bottom',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button1_margin_left',
            label: 'Margin left',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          // {
          //   key: 'button1_enable_button_label_2',
          //   label: 'Enable button label 2',
          //   initial_value: false,
          //   conditional_fields: [
          //     {
          //       key: 'assets/button1_switch',
          //       condition_value: true,
          //     },
          //   ],
          //   type: 'switch',
          // },
          // {
          //   key: 'button1_font_color_2',
          //   label: 'Font color',
          //   initial_value: 'rgba(239, 239, 239, 1)',
          //   conditional_fields: [
          //     {
          //       key: 'assets/button1_enable_button_label_2',
          //       condition_value: true,
          //     },
          //     {
          //       key: 'assets/button1_switch',
          //       condition_value: true,
          //     },
          //   ],
          //   type: 'color_picker_rgba',
          // },
          // {
          //   key: 'button1_hover_font_color_2',
          //   label: 'Hover font color',
          //   initial_value: 'rgba(239, 239, 239, 1)',
          //   conditional_fields: [
          //     {
          //       key: 'assets/button1_enable_button_label_2',
          //       condition_value: true,
          //     },
          //     {
          //       key: 'assets/button1_switch',
          //       condition_value: true,
          //     },
          //   ],
          //   type: 'color_picker_rgba',
          // },
          // {
          //   key: 'button1_font_family_2',
          //   label: 'Font family',
          //   initial_value: 'Ubuntu-Medium',
          //   conditional_fields: [
          //     {
          //       key: 'assets/button1_enable_button_label_2',
          //       condition_value: true,
          //     },
          //     {
          //       key: 'assets/button1_switch',
          //       condition_value: true,
          //     },
          //   ],
          //   type: 'web_font_selector',
          // },
          // {
          //   key: 'button1_font_size_2',
          //   label: 'Font size',
          //   initial_value: 14,
          //   conditional_fields: [
          //     {
          //       key: 'assets/button1_enable_button_label_2',
          //       condition_value: true,
          //     },
          //     {
          //       key: 'assets/button1_switch',
          //       condition_value: true,
          //     },
          //   ],
          //   type: 'number_input',
          // },
          // {
          //   key: 'button1_line_height_2',
          //   label: 'Line height',
          //   initial_value: 24,
          //   conditional_fields: [
          //     {
          //       key: 'assets/button1_enable_button_label_2',
          //       condition_value: true,
          //     },
          //     {
          //       key: 'assets/button1_switch',
          //       condition_value: true,
          //     },
          //   ],
          //   type: 'number_input',
          // },
          // {
          //   key: 'button1_letter_spacing_2',
          //   label: 'Letter spacing',
          //   initial_value: 0,
          //   conditional_fields: [
          //     {
          //       key: 'assets/button1_enable_button_label_2',
          //       condition_value: true,
          //     },
          //     {
          //       key: 'assets/button1_switch',
          //       condition_value: true,
          //     },
          //   ],
          //   type: 'number_input',
          // },
          // {
          //   key: 'button1_text_alignment_2',
          //   label: 'Text alignment',
          //   options: [
          //     {
          //       text: 'Left',
          //       value: 'left',
          //     },
          //     {
          //       text: 'Center',
          //       value: 'center',
          //     },
          //     {
          //       text: 'Right',
          //       value: 'right',
          //     },
          //   ],
          //   initial_value: 'left',
          //   conditional_fields: [
          //     {
          //       key: 'assets/button1_enable_button_label_2',
          //       condition_value: true,
          //     },
          //     {
          //       key: 'assets/button1_switch',
          //       condition_value: true,
          //     },
          //   ],
          //   type: 'select',
          // },
          // {
          //   key: 'button1_text_transform_2',
          //   label: 'Text transform',
          //   options: [
          //     {
          //       text: 'Default',
          //       value: 'default',
          //     },
          //     {
          //       text: 'Uppercase',
          //       value: 'uppercase',
          //     },
          //     {
          //       text: 'Lowercase',
          //       value: 'lowercase',
          //     },
          //     {
          //       text: 'Capitalize',
          //       value: 'capitalize',
          //     },
          //   ],
          //   initial_value: 'default',
          //   conditional_fields: [
          //     {
          //       key: 'assets/button1_enable_button_label_2',
          //       condition_value: true,
          //     },
          //     {
          //       key: 'assets/button1_switch',
          //       condition_value: true,
          //     },
          //   ],
          //   type: 'select',
          // },
          // {
          //   key: 'button1_default_text_decoration_2',
          //   label: 'Default text decoration',
          //   options: [
          //     {
          //       text: 'None',
          //       value: 'none',
          //     },
          //     {
          //       text: 'Underline',
          //       value: 'underline',
          //     },
          //     {
          //       text: 'Strikethrough',
          //       value: 'line-through',
          //     },
          //   ],
          //   initial_value: 'none',
          //   conditional_fields: [
          //     {
          //       key: 'assets/button1_enable_button_label_2',
          //       condition_value: true,
          //     },
          //     {
          //       key: 'assets/button1_switch',
          //       condition_value: true,
          //     },
          //   ],
          //   type: 'select',
          // },
          // {
          //   key: 'button1_hover_text_decoration_2',
          //   label: 'Hover text decoration',
          //   options: [
          //     {
          //       text: 'None',
          //       value: 'none',
          //     },
          //     {
          //       text: 'Underline',
          //       value: 'underline',
          //     },
          //     {
          //       text: 'Strikethrough',
          //       value: 'line-through',
          //     },
          //   ],
          //   initial_value: 'none',
          //   conditional_fields: [
          //     {
          //       key: 'assets/button1_enable_button_label_2',
          //       condition_value: true,
          //     },
          //     {
          //       key: 'assets/button1_switch',
          //       condition_value: true,
          //     },
          //   ],
          //   type: 'select',
          // },
          // {
          //   key: 'button1_number_of_lines_2',
          //   label: 'Number of lines',
          //   initial_value: 1,
          //   conditional_fields: [
          //     {
          //       key: 'assets/button1_enable_button_label_2',
          //       condition_value: true,
          //     },
          //     {
          //       key: 'assets/button1_switch',
          //       condition_value: true,
          //     },
          //   ],
          //   type: 'number_input',
          // },
          // {
          //   key: 'button1_margin_top_2',
          //   label: 'Margin top',
          //   initial_value: 0,
          //   conditional_fields: [
          //     {
          //       key: 'assets/button1_switch',
          //       condition_value: true,
          //     },
          //   ],
          //   type: 'number_input',
          // },
          // {
          //   key: 'button1_margin_right_2',
          //   label: 'Margin right',
          //   initial_value: 0,
          //   conditional_fields: [
          //     {
          //       key: 'assets/button1_switch',
          //       condition_value: true,
          //     },
          //   ],
          //   type: 'number_input',
          // },
          // {
          //   key: 'button1_margin_bottom_2',
          //   label: 'Margin bottom',
          //   initial_value: 0,
          //   conditional_fields: [
          //     {
          //       key: 'assets/button1_switch',
          //       condition_value: true,
          //     },
          //   ],
          //   type: 'number_input',
          // },
          // {
          //   key: 'button1_margin_left_2',
          //   label: 'Margin left',
          //   initial_value: 0,
          //   conditional_fields: [
          //     {
          //       key: 'assets/button1_switch',
          //       condition_value: true,
          //     },
          //   ],
          //   type: 'number_input',
          // },
        ],
      },
      // #endregion
      // #region Button 2
      {
        group: true,
        label: 'Button 2',
        folded: false,
        fields: [
          //   ------ V2 ------
          {
            key: 'button2_switch',
            label: 'enable',
            initial_value: false,
            type: 'switch',
          },
          {
            key: 'button2_assign_action',
            label: 'Assign Action',
            options: [
              {
                text: 'Play',
                value: 'play',
              },
              {
                text: 'Favorite',
                value: 'favorite',
              },
              {
                text: 'Trailer',
                value: 'trailer',
              },
            ],
            initial_value: 'favorite',
            type: 'select',
          },
          {
            key: 'button2_display_mode',
            label: 'Display mode',
            options: [
              {
                text: 'Dynamic',
                value: 'dynamic',
              },
              {
                text: 'Fixed',
                value: 'fixed',
              },
              {
                text: 'Fill',
                value: 'fill',
              },
            ],
            initial_value: 'dynamic',
            type: 'select',
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'button2_width',
            label: 'Width',
            initial_value: 120,
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
            label_tooltip: 'Width: Applies to Fixed display mode',
          },
          {
            key: 'button2_content_alignment',
            label: 'Button Content Alignment',
            type: 'select',
            options: [
              {
                text: 'Left',
                value: 'left',
              },
              {
                text: 'Center',
                value: 'center',
              },
              {
                text: 'Right',
                value: 'right',
              },
            ],
            initial_value: 'center',
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            label_tooltip:
              'Button content alignment: Applies to Fixed and Fill display modes',
          },
          {
            key: 'button2_background_color',
            label: 'Background color',
            initial_value: 'rgba(239, 239, 239, 0.25)',
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button2_hover_background_color',
            label: 'Hover background color',
            initial_value: 'rgba(254, 20, 72, 1)',
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button2_border_color',
            label: 'Border color',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button2_hover_border_color',
            label: 'Hover border color',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button2_border_size',
            label: 'Border size',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_corner_radius',
            label: 'Corner radius',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_padding_top',
            label: 'Padding top',
            initial_value: 12,
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_padding_right',
            label: 'Padding right',
            initial_value: 20,
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_padding_bottom',
            label: 'Padding bottom',
            initial_value: 12,
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_padding_left',
            label: 'Padding left',
            initial_value: 12,
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_enable_asset',
            label: 'Enable asset',
            initial_value: true,
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'switch',
          },
          {
            key: 'button2_asset_alignment',
            label: 'Asset alignment',
            options: [
              {
                text: 'Left',
                value: 'left',
              },
              {
                text: 'Right',
                value: 'right',
              },
            ],
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'assets/button2_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button2_asset_height',
            label: 'Asset height',
            initial_value: 24,
            conditional_fields: [
              {
                key: 'assets/button2_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_asset_width',
            label: 'Asset width',
            initial_value: 24,
            conditional_fields: [
              {
                key: 'assets/button2_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_asset_margin_top',
            label: 'Asset margin top',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button2_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_asset_margin_right',
            label: 'Asset margin right',
            initial_value: 4,
            conditional_fields: [
              {
                key: 'assets/button2_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_asset_margin_bottom',
            label: 'Asset margin bottom',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button2_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_asset_margin_left',
            label: 'Asset margin left',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button2_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_enable_label_1',
            label: 'Enable label 1',
            initial_value: true,
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'switch',
          },
          {
            key: 'button2_font_color',
            label: 'Font color',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'assets/button2_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button2_hover_font_color',
            label: 'Hover font color',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'assets/button2_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button2_font_family',
            label: 'Font family',
            initial_value: 'Ubuntu-Medium',
            conditional_fields: [
              {
                key: 'assets/button2_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'web_font_selector',
          },
          {
            key: 'button2_font_size',
            label: 'Font size',
            initial_value: 14,
            conditional_fields: [
              {
                key: 'assets/button2_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_line_height',
            label: 'Line height',
            initial_value: 24,
            conditional_fields: [
              {
                key: 'assets/button2_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_letter_spacing',
            label: 'Letter spacing',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button2_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_text_alignment',
            label: 'Text alignment',
            options: [
              {
                text: 'Left',
                value: 'left',
              },
              {
                text: 'Center',
                value: 'center',
              },
              {
                text: 'Right',
                value: 'right',
              },
            ],
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'assets/button2_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button2_text_transform',
            label: 'Text transform',
            options: [
              {
                text: 'Default',
                value: 'default',
              },
              {
                text: 'Uppercase',
                value: 'uppercase',
              },
              {
                text: 'Lowercase',
                value: 'lowercase',
              },
              {
                text: 'Capitalize',
                value: 'capitalize',
              },
            ],
            initial_value: 'default',
            conditional_fields: [
              {
                key: 'assets/button2_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button2_default_text_decoration',
            label: 'Default text decoration',
            options: [
              {
                text: 'None',
                value: 'none',
              },
              {
                text: 'Underline',
                value: 'underline',
              },
              {
                text: 'Strikethrough',
                value: 'line-through',
              },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'assets/button2_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button2_hover_text_decoration',
            label: 'Hover text decoration',
            options: [
              {
                text: 'None',
                value: 'none',
              },
              {
                text: 'Underline',
                value: 'underline',
              },
              {
                text: 'Strikethrough',
                value: 'line-through',
              },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'assets/button2_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button2_number_of_lines',
            label: 'Number of lines',
            initial_value: 1,
            conditional_fields: [
              {
                key: 'assets/button2_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_margin_top',
            label: 'Margin top',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_margin_right',
            label: 'Margin right',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_margin_bottom',
            label: 'Margin bottom',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_margin_left',
            label: 'Margin left',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_enable_button_label_2',
            label: 'Enable button label 2',
            initial_value: false,
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'switch',
          },
          {
            key: 'button2_font_color_2',
            label: 'Font color',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'assets/button2_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button2_hover_font_color_2',
            label: 'Hover font color',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'assets/button2_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button2_font_family_2',
            label: 'Font family',
            initial_value: 'Ubuntu-Medium',
            conditional_fields: [
              {
                key: 'assets/button2_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'web_font_selector',
          },
          {
            key: 'button2_font_size_2',
            label: 'Font size',
            initial_value: 14,
            conditional_fields: [
              {
                key: 'assets/button2_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_line_height_2',
            label: 'Line height',
            initial_value: 24,
            conditional_fields: [
              {
                key: 'assets/button2_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_letter_spacing_2',
            label: 'Letter spacing',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button2_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_text_alignment_2',
            label: 'Text alignment',
            options: [
              {
                text: 'Left',
                value: 'left',
              },
              {
                text: 'Center',
                value: 'center',
              },
              {
                text: 'Right',
                value: 'right',
              },
            ],
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'assets/button2_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button2_text_transform_2',
            label: 'Text transform',
            options: [
              {
                text: 'Default',
                value: 'default',
              },
              {
                text: 'Uppercase',
                value: 'uppercase',
              },
              {
                text: 'Lowercase',
                value: 'lowercase',
              },
              {
                text: 'Capitalize',
                value: 'capitalize',
              },
            ],
            initial_value: 'default',
            conditional_fields: [
              {
                key: 'assets/button2_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button2_default_text_decoration_2',
            label: 'Default text decoration',
            options: [
              {
                text: 'None',
                value: 'none',
              },
              {
                text: 'Underline',
                value: 'underline',
              },
              {
                text: 'Strikethrough',
                value: 'line-through',
              },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'assets/button2_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button2_hover_text_decoration_2',
            label: 'Hover text decoration',
            options: [
              {
                text: 'None',
                value: 'none',
              },
              {
                text: 'Underline',
                value: 'underline',
              },
              {
                text: 'Strikethrough',
                value: 'line-through',
              },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'assets/button2_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button2_number_of_lines_2',
            label: 'Number of lines',
            initial_value: 1,
            conditional_fields: [
              {
                key: 'assets/button2_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_margin_top_2',
            label: 'Margin top',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_margin_right_2',
            label: 'Margin right',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_margin_bottom_2',
            label: 'Margin bottom',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button2_margin_left_2',
            label: 'Margin left',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
        ],
      },
      // #endregion
      // #region Button 3
      {
        group: true,
        label: 'Button 3',
        folded: false,
        fields: [
          //   ------ V2 ------
          {
            key: 'button3_switch',
            label: 'enable',
            initial_value: false,
            type: 'switch',
          },
          {
            key: 'button3_assign_action',
            label: 'Assign Action',
            options: [
              {
                text: 'Play',
                value: 'play',
              },
              {
                text: 'Favorite',
                value: 'favorite',
              },
              {
                text: 'Trailer',
                value: 'trailer',
              },
            ],
            initial_value: 'trailer',
            type: 'select',
          },
          {
            key: 'button3_display_mode',
            label: 'Display mode',
            options: [
              {
                text: 'Dynamic',
                value: 'dynamic',
              },
              {
                text: 'Fixed',
                value: 'fixed',
              },
              {
                text: 'Fill',
                value: 'fill',
              },
            ],
            initial_value: 'dynamic',
            type: 'select',
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'button3_width',
            label: 'Width',
            initial_value: 120,
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
            label_tooltip: 'Width: Applies to Fixed display mode',
          },
          {
            key: 'button3_content_alignment',
            label: 'Button Content Alignment',
            type: 'select',
            options: [
              {
                text: 'Left',
                value: 'left',
              },
              {
                text: 'Center',
                value: 'center',
              },
              {
                text: 'Right',
                value: 'right',
              },
            ],
            initial_value: 'center',
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            label_tooltip:
              'Button content alignment: Applies to Fixed and Fill display modes',
          },
          {
            key: 'button3_background_color',
            label: 'Background color',
            initial_value: 'rgba(239, 239, 239, 0.25)',
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button3_hover_background_color',
            label: 'Hover background color',
            initial_value: 'rgba(254, 20, 72, 1)',
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button3_border_color',
            label: 'Border color',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button3_hover_border_color',
            label: 'Hover border color',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button3_border_size',
            label: 'Border size',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_corner_radius',
            label: 'Corner radius',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_padding_top',
            label: 'Padding top',
            initial_value: 12,
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_padding_right',
            label: 'Padding right',
            initial_value: 20,
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_padding_bottom',
            label: 'Padding bottom',
            initial_value: 12,
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_padding_left',
            label: 'Padding left',
            initial_value: 12,
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_enable_asset',
            label: 'Enable asset',
            initial_value: true,
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'switch',
          },
          {
            key: 'button3_asset_alignment',
            label: 'Asset alignment',
            options: [
              {
                text: 'Left',
                value: 'left',
              },
              {
                text: 'Right',
                value: 'right',
              },
            ],
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'assets/button3_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button3_asset_height',
            label: 'Asset height',
            initial_value: 24,
            conditional_fields: [
              {
                key: 'assets/button3_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_asset_width',
            label: 'Asset width',
            initial_value: 24,
            conditional_fields: [
              {
                key: 'assets/button3_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_asset_margin_top',
            label: 'Asset margin top',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button3_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_asset_margin_right',
            label: 'Asset margin right',
            initial_value: 4,
            conditional_fields: [
              {
                key: 'assets/button3_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_asset_margin_bottom',
            label: 'Asset margin bottom',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button3_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_asset_margin_left',
            label: 'Asset margin left',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button3_enable_asset',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_enable_label_1',
            label: 'Enable label 1',
            initial_value: true,
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'switch',
          },
          {
            key: 'button3_font_color',
            label: 'Font color',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'assets/button3_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button3_hover_font_color',
            label: 'Hover font color',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'assets/button3_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button3_font_family',
            label: 'Font family',
            initial_value: 'Ubuntu-Medium',
            conditional_fields: [
              {
                key: 'assets/button3_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'web_font_selector',
          },
          {
            key: 'button3_font_size',
            label: 'Font size',
            initial_value: 14,
            conditional_fields: [
              {
                key: 'assets/button3_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_line_height',
            label: 'Line height',
            initial_value: 24,
            conditional_fields: [
              {
                key: 'assets/button3_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_letter_spacing',
            label: 'Letter spacing',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button3_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_text_alignment',
            label: 'Text alignment',
            options: [
              {
                text: 'Left',
                value: 'left',
              },
              {
                text: 'Center',
                value: 'center',
              },
              {
                text: 'Right',
                value: 'right',
              },
            ],
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'assets/button3_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button3_text_transform',
            label: 'Text transform',
            options: [
              {
                text: 'Default',
                value: 'default',
              },
              {
                text: 'Uppercase',
                value: 'uppercase',
              },
              {
                text: 'Lowercase',
                value: 'lowercase',
              },
              {
                text: 'Capitalize',
                value: 'capitalize',
              },
            ],
            initial_value: 'default',
            conditional_fields: [
              {
                key: 'assets/button3_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button3_default_text_decoration',
            label: 'Default text decoration',
            options: [
              {
                text: 'None',
                value: 'none',
              },
              {
                text: 'Underline',
                value: 'underline',
              },
              {
                text: 'Strikethrough',
                value: 'line-through',
              },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'assets/button3_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button3_hover_text_decoration',
            label: 'Hover text decoration',
            options: [
              {
                text: 'None',
                value: 'none',
              },
              {
                text: 'Underline',
                value: 'underline',
              },
              {
                text: 'Strikethrough',
                value: 'line-through',
              },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'assets/button3_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button3_number_of_lines',
            label: 'Number of lines',
            initial_value: 1,
            conditional_fields: [
              {
                key: 'assets/button3_enable_label_1',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_margin_top',
            label: 'Margin top',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_margin_right',
            label: 'Margin right',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_margin_bottom',
            label: 'Margin bottom',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_margin_left',
            label: 'Margin left',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_enable_button_label_2',
            label: 'Enable button label 2',
            initial_value: false,
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'switch',
          },
          {
            key: 'button3_font_color_2',
            label: 'Font color',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'assets/button3_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button3_hover_font_color_2',
            label: 'Hover font color',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'assets/button3_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button3_font_family_2',
            label: 'Font family',
            initial_value: 'Ubuntu-Medium',
            conditional_fields: [
              {
                key: 'assets/button3_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'web_font_selector',
          },
          {
            key: 'button3_font_size_2',
            label: 'Font size',
            initial_value: 14,
            conditional_fields: [
              {
                key: 'assets/button3_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_line_height_2',
            label: 'Line height',
            initial_value: 24,
            conditional_fields: [
              {
                key: 'assets/button3_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_letter_spacing_2',
            label: 'Letter spacing',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button3_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_text_alignment_2',
            label: 'Text alignment',
            options: [
              {
                text: 'Left',
                value: 'left',
              },
              {
                text: 'Center',
                value: 'center',
              },
              {
                text: 'Right',
                value: 'right',
              },
            ],
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'assets/button3_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button3_text_transform_2',
            label: 'Text transform',
            options: [
              {
                text: 'Default',
                value: 'default',
              },
              {
                text: 'Uppercase',
                value: 'uppercase',
              },
              {
                text: 'Lowercase',
                value: 'lowercase',
              },
              {
                text: 'Capitalize',
                value: 'capitalize',
              },
            ],
            initial_value: 'default',
            conditional_fields: [
              {
                key: 'assets/button3_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button3_default_text_decoration_2',
            label: 'Default text decoration',
            options: [
              {
                text: 'None',
                value: 'none',
              },
              {
                text: 'Underline',
                value: 'underline',
              },
              {
                text: 'Strikethrough',
                value: 'line-through',
              },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'assets/button3_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button3_hover_text_decoration_2',
            label: 'Hover text decoration',
            options: [
              {
                text: 'None',
                value: 'none',
              },
              {
                text: 'Underline',
                value: 'underline',
              },
              {
                text: 'Strikethrough',
                value: 'line-through',
              },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'assets/button3_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'button3_number_of_lines_2',
            label: 'Number of lines',
            initial_value: 1,
            conditional_fields: [
              {
                key: 'assets/button3_enable_button_label_2',
                condition_value: true,
              },
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_margin_top_2',
            label: 'Margin top',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_margin_right_2',
            label: 'Margin right',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_margin_bottom_2',
            label: 'Margin bottom',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'button3_margin_left_2',
            label: 'Margin left',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/button3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
        ],
      },
      // #endregion
    ],
  },
} as const;

export default manifest;
