import { twMerge } from 'tailwind-merge';
import {
  getCell3IgnorePaddingClasses,
  getCellVisibillityClasses,
  getTextAlignmentClasses,
  getTextAlignmentMarginClasses,
  shouldRenderPowerCellLabel,
  type Identifiers,
} from './power-cell-getters';
import { ButtonsContainer } from './buttons-container';
import LabelV2 from './label-v2';
import { type TextComponent } from './responsive-text';
import { type UseResponsiveBreakpoint } from '~/hooks/use-responsive-breakpoint';
import type { FeedEntry } from '~/services/layout/index.server';
import { getButtonsContainerPositionClasses } from './buttons-container-getters';

export function LabelWrapper({
  textLabelNames,
  assets,
  textLabels,
  textLabelsAlignment,
  textLabelClasses,
  defaultStyles,
  entryWebLink,
  identifiers,
  trackAnalyticsData,
  responsiveBreakpoint,
  entry,
  isLoading,
}: {
  textLabelNames: string[];
  assets: any;
  textLabels: any;
  textLabelsAlignment: any;
  textLabelClasses: any;
  defaultStyles: any;
  entryWebLink: string;
  identifiers: Identifiers;
  trackAnalyticsData: Function;
  responsiveBreakpoint: UseResponsiveBreakpoint;
  entry: FeedEntry;
  isLoading?: boolean;
}) {
  return textLabelNames.map((textLabelName) => {
    if (
      !shouldRenderPowerCellLabel({
        textLabels,
        textLabelName,
      })
    )
      return null;

    return (
      <div
        className={twMerge(
          getCellVisibillityClasses({
            mobile: textLabels.mobile[textLabelName],
            tablet: textLabels.tablet[textLabelName],
            desktop: textLabels.desktop[textLabelName],
            'large-desktop': textLabels['large-desktop'][textLabelName],
          }),
          getButtonsContainerPositionClasses(assets, textLabelName),
          getCell3IgnorePaddingClasses(identifiers, 'label wrapper')
        )}
        key={textLabelName}
      >
        <div
          className={`w-fit ${getTextAlignmentMarginClasses({
            mobile: textLabelsAlignment.mobile[textLabelName],
            tablet: textLabelsAlignment.tablet[textLabelName],
            desktop: textLabelsAlignment.desktop[textLabelName],
            'large-desktop':
              textLabelsAlignment['large-desktop'][textLabelName],
          })}`}
        >
          <div
            className={`[&_*]:line-clamp-label-line-clamp [&_*]:leading-label-line-height ${getTextAlignmentClasses(
              {
                mobile: textLabelsAlignment.mobile[textLabelName],
                tablet: textLabelsAlignment.tablet[textLabelName],
                desktop: textLabelsAlignment.desktop[textLabelName],
                'large-desktop':
                  textLabelsAlignment['large-desktop'][textLabelName],
              }
            )}`}
          >
            <LabelV2
              responsiveText={{
                mobile: textLabels.mobile[textLabelName],
                tablet: textLabels.tablet[textLabelName],
                desktop: textLabels.desktop[textLabelName],
                'large-desktop': textLabels['large-desktop'][textLabelName],
              }}
              className={textLabelClasses[textLabelName]}
              componentTextLabelTypography={
                defaultStyles[`${textLabelName}_typography`] as TextComponent
              }
              isLoading={isLoading}
            />
          </div>
        </div>
        <ButtonsContainer
          assets={assets}
          textLabelKeyName={textLabelName}
          entryWebLink={entryWebLink}
          trackAnalyticsData={trackAnalyticsData}
          responsiveBreakpoint={responsiveBreakpoint}
          entry={entry}
        />
      </div>
    );
  });
}
