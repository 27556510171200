import logger from '~/services/logger';
import { useRef, useState } from 'react';
import type {
  Feed,
  FeedEntry,
  UIComponent,
} from '~/services/layout/index.server.ts';
import { useNextFeedPagination } from '~/hooks/use-next-feed.ts';
import { useFeedEntries } from '~/hooks/use-feed-entries.ts';
import { PowerCell } from '../../power-cell/components';
import manifest from '../config/manifest';
import { getPluginConfig } from '~/utils/get-plugin-configuration';
import { useFeedEntriesOnSearchQueryChange } from '~/hooks/use-feed-entries-on-search';
import { usePaginationCondition } from '~/hooks/use-pagination-condition';

export default function GridV2({
  uiComponent,
  feed,
  isLoading,
  isClient,
}: {
  uiComponent: UIComponent;
  feed?: Feed;
  isLoading?: boolean;
  isClient?: boolean;
}): JSX.Element {
  const config = getPluginConfig<typeof manifest>(manifest, uiComponent);

  const selectable: boolean = !!config.rules.component_cells_selectable;

  const ref = useRef<any>(null);

  const [feedEntries, setFeedEntries] = useState<FeedEntry[]>([]);

  const [nextFeedUrl, setNextFeedUrl] = useState<string>(feed?.next || '');

  const [isNextFeedLoading, setIsNextFeedLoading] = useState<boolean>(false);

  const itemLimit: number | undefined = config.rules?.item_limit;

  const entries = useFeedEntries({
    feedEntries,
    setFeedEntries,
    isLoading,
    isClient,
    feed,
    itemLimit,
  });

  useFeedEntriesOnSearchQueryChange({
    setFeedEntries,
    entries,
  });

  const nextFeedPaginationCondition = usePaginationCondition({
    ref,
    isLoading,
    componentType: 'grid_v2',
  });

  useNextFeedPagination({
    feed,
    nextFeedUrl,
    setNextFeedUrl,
    setIsNextFeedLoading,
    isNextFeedLoading,
    isLoading,
    setFeedEntries,
    feedEntries,
    itemLimit,
    condition: nextFeedPaginationCondition,
  });

  try {
    return (
      <div className="flex justify-evenly" ref={ref}>
        <section
          className={`
          component-${uiComponent.id} component-classes grid-cols grid h-fit w-full shrink-0 overflow-x-auto overflow-y-hidden border-none scrollbar-hide`}
        >
          {entries?.map((entry, i) => {
            return (
              <PowerCell
                key={entry?._key || `${entry.id}-${i}`}
                uiComponent={uiComponent}
                entry={entry}
                selectable={selectable}
                isLoading={isLoading}
              />
            );
          })}
        </section>
      </div>
    );
  } catch (error: any) {
    logger.info(`Grid: ${error.message}`);
    return <></>;
  }
}
