import logger from '~/services/logger';
import type { Screen } from '../layout/index.ts';
import { getColor, getPixels } from '~/utils/css-units.ts';
import get from 'lodash.get';
import manifest from '../plugins/search_v2/config/manifest.ts';
import { getPluginConfig } from '~/utils/get-plugin-configuration.ts';

export default function getSearchV2Vars(screens: Screen[]) {
  try {
    const searchScreen: Screen | undefined = screens?.find(
      (screen: Screen) => screen.type === 'search_v2'
    );

    if (!searchScreen) return {};

    const { styles } = getPluginConfig<typeof manifest>(manifest, searchScreen);

    return {
      [`.search-v2-${searchScreen.id}`]: {
        '--search_icon_asset': `url('${
          get(styles, 'search_icon_asset') || '/search_icon_asset.svg'
        }')`,
        '--search_icon_focused_asset': `url('${get(
          styles,
          'search_icon_focused_asset'
        )}')`,
        '--clear_icon_asset': `url('${
          get(styles, 'clear_icon_asset') || '/search_clear_icon_asset.svg'
        }')`,
        '--clear_icon_hover_asset': `url('${
          get(styles, 'clear_icon_hover_asset') ||
          '/search_clear_icon_hover_asset.svg'
        }')`,

        '--search_bar_container_mobile_position': get(
          styles,
          'search_bar_container_mobile_position'
        ),
        '--search_bar_container_tablet_position': get(
          styles,
          'search_bar_container_tablet_position'
        ),
        '--search_bar_container_desktop_position': get(
          styles,
          'search_bar_container_desktop_position'
        ),
        '--search_bar_container_large_desktop_position': get(
          styles,
          'search_bar_container_large_desktop_position'
        ),
        '--search_bar_container_mobile_y_position': getPixels(
          get(styles, 'search_bar_container_mobile_position') === 'fixed'
            ? get(styles, 'search_bar_container_mobile_y_position')
            : 0
        ),
        '--search_bar_container_tablet_y_position': getPixels(
          get(styles, 'search_bar_container_tablet_position') === 'fixed'
            ? get(styles, 'search_bar_container_tablet_y_position')
            : 0
        ),
        '--search_bar_container_desktop_y_position': getPixels(
          get(styles, 'search_bar_container_desktop_position') === 'fixed'
            ? get(styles, 'search_bar_container_desktop_y_position')
            : 0
        ),
        '--search_bar_container_large_desktop_y_position': getPixels(
          get(styles, 'search_bar_container_large_desktop_position') === 'fixed'
            ? get(styles, 'search_bar_container_large_desktop_y_position')
            : 0
        ),

        '--search_bar_container_mobile_background_color': getColor(
          get(styles, 'search_bar_container_mobile_background_color')
        ),
        '--search_bar_container_tablet_background_color': getColor(
          get(styles, 'search_bar_container_tablet_background_color')
        ),
        '--search_bar_container_desktop_background_color': getColor(
          get(styles, 'search_bar_container_desktop_background_color')
        ),
        '--search_bar_container_large_desktop_background_color': getColor(
          get(styles, 'search_bar_container_large_desktop_background_color')
        ),
        '--search_bar_container_mobile_padding_top': getPixels(
          get(styles, 'search_bar_container_mobile_padding_top')
        ),
        '--search_bar_container_tablet_padding_top': getPixels(
          get(styles, 'search_bar_container_tablet_padding_top')
        ),
        '--search_bar_container_desktop_padding_top': getPixels(
          get(styles, 'search_bar_container_desktop_padding_top')
        ),
        '--search_bar_container_large_desktop_padding_top': getPixels(
          get(styles, 'search_bar_container_large_desktop_padding_top')
        ),
        '--search_bar_container_mobile_padding_right': getPixels(
          get(styles, 'search_bar_container_mobile_padding_right')
        ),
        '--search_bar_container_tablet_padding_right': getPixels(
          get(styles, 'search_bar_container_tablet_padding_right')
        ),
        '--search_bar_container_desktop_padding_right': getPixels(
          get(styles, 'search_bar_container_desktop_padding_right')
        ),
        '--search_bar_container_large_desktop_padding_right': getPixels(
          get(styles, 'search_bar_container_large_desktop_padding_right')
        ),
        '--search_bar_container_mobile_padding_bottom': getPixels(
          get(styles, 'search_bar_container_mobile_padding_bottom')
        ),
        '--search_bar_container_tablet_padding_bottom': getPixels(
          get(styles, 'search_bar_container_tablet_padding_bottom')
        ),
        '--search_bar_container_desktop_padding_bottom': getPixels(
          get(styles, 'search_bar_container_desktop_padding_bottom')
        ),
        '--search_bar_container_large_desktop_padding_bottom': getPixels(
          get(styles, 'search_bar_container_large_desktop_padding_bottom')
        ),
        '--search_bar_container_mobile_padding_left': getPixels(
          get(styles, 'search_bar_container_mobile_padding_left')
        ),
        '--search_bar_container_tablet_padding_left': getPixels(
          get(styles, 'search_bar_container_tablet_padding_left')
        ),
        '--search_bar_container_desktop_padding_left': getPixels(
          get(styles, 'search_bar_container_desktop_padding_left')
        ),
        '--search_bar_container_large_desktop_padding_left': getPixels(
          get(styles, 'search_bar_container_large_desktop_padding_left')
        ),

        '--search_bar_container_mobile_margin_top': getPixels(
          get(styles, 'search_bar_container_mobile_margin_top')
        ),
        '--search_bar_container_tablet_margin_top': getPixels(
          get(styles, 'search_bar_container_tablet_margin_top')
        ),
        '--search_bar_container_desktop_margin_top': getPixels(
          get(styles, 'search_bar_container_desktop_margin_top')
        ),
        '--search_bar_container_large_desktop_margin_top': getPixels(
          get(styles, 'search_bar_container_large_desktop_margin_top')
        ),

        '--search_bar_container_mobile_margin_right': getPixels(
          get(styles, 'search_bar_container_mobile_margin_right')
        ),
        '--search_bar_container_tablet_margin_right': getPixels(
          get(styles, 'search_bar_container_tablet_margin_right')
        ),
        '--search_bar_container_desktop_margin_right': getPixels(
          get(styles, 'search_bar_container_desktop_margin_right')
        ),
        '--search_bar_container_large_desktop_margin_right': getPixels(
          get(styles, 'search_bar_container_large_desktop_margin_right')
        ),
        '--search_bar_container_mobile_margin_bottom': getPixels(
          get(styles, 'search_bar_container_mobile_margin_bottom')
        ),
        '--search_bar_container_tablet_margin_bottom': getPixels(
          get(styles, 'search_bar_container_tablet_margin_bottom')
        ),
        '--search_bar_container_desktop_margin_bottom': getPixels(
          get(styles, 'search_bar_container_desktop_margin_bottom')
        ),
        '--search_bar_container_large_desktop_margin_bottom': getPixels(
          get(styles, 'search_bar_container_large_desktop_margin_bottom')
        ),
        '--search_bar_container_mobile_margin_left': getPixels(
          get(styles, 'search_bar_container_mobile_margin_left')
        ),
        '--search_bar_container_tablet_margin_left': getPixels(
          get(styles, 'search_bar_container_tablet_margin_left')
        ),
        '--search_bar_container_desktop_margin_left': getPixels(
          get(styles, 'search_bar_container_desktop_margin_left')
        ),
        '--search_bar_container_large_desktop_margin_left': getPixels(
          get(styles, 'search_bar_container_large_desktop_margin_left')
        ),

        '--search_bar_background_color': getColor(
          get(styles, 'search_bar_background_color')
        ),
        '--search_bar_focused_background_color': getColor(
          get(styles, 'search_bar_focused_background_color')
        ),
        '--search_bar_border_color': getColor(
          get(styles, 'search_bar_border_color')
        ),

        '--search_bar_focused_border_color': getColor(
          get(styles, 'search_bar_focused_border_color')
        ),
        '--search_bar_mobile_border_size': getPixels(
          get(styles, 'search_bar_mobile_border_size')
        ),
        '--search_bar_tablet_border_size': getPixels(
          get(styles, 'search_bar_tablet_border_size')
        ),
        '--search_bar_desktop_border_size': getPixels(
          get(styles, 'search_bar_desktop_border_size')
        ),
        '--search_bar_large_desktop_border_size': getPixels(
          get(styles, 'search_bar_large_desktop_border_size')
        ),
        '--search_bar_underline_bottom_color': getColor(
          get(styles, 'search_bar_underline_bottom_color')
        ),
        '--search_bar_focused_underline_bottom_color': getColor(
          get(styles, 'search_bar_focused_underline_bottom_color')
        ),
        '--search_bar_underline_mobile_size': getPixels(
          get(styles, 'search_bar_underline_mobile_size')
        ),
        '--search_bar_underline_tablet_size': getPixels(
          get(styles, 'search_bar_underline_tablet_size')
        ),
        '--search_bar_underline_desktop_size': getPixels(
          get(styles, 'search_bar_underline_desktop_size')
        ),
        '--search_bar_underline_large_desktop_size': getPixels(
          get(styles, 'search_bar_underline_large_desktop_size')
        ),
        '--search_bar_underline_mobile_corner_radius': getPixels(
          get(styles, 'search_bar_underline_mobile_corner_radius')
        ),
        '--search_bar_underline_tablet_corner_radius': getPixels(
          get(styles, 'search_bar_underline_tablet_corner_radius')
        ),
        '--search_bar_underline_desktop_corner_radius': getPixels(
          get(styles, 'search_bar_underline_desktop_corner_radius')
        ),
        '--search_bar_underline_large_desktop_corner_radius': getPixels(
          get(styles, 'search_bar_underline_large_desktop_corner_radius')
        ),
        '--search_bar_mobile_corner_radius': getPixels(
          get(styles, 'search_bar_mobile_corner_radius')
        ),
        '--search_bar_tablet_corner_radius': getPixels(
          get(styles, 'search_bar_tablet_corner_radius')
        ),
        '--search_bar_desktop_corner_radius': getPixels(
          get(styles, 'search_bar_desktop_corner_radius')
        ),
        '--search_bar_large_desktop_corner_radius': getPixels(
          get(styles, 'search_bar_large_desktop_corner_radius')
        ),

        '--search_bar_mobile_padding_top': getPixels(
          Math.max(
            get(styles, 'search_bar_mobile_padding_top'),
            get(styles, 'search_label_mobile_margin_top'),
            get(styles, 'search_icon_mobile_margin_top'),
            get(styles, 'clear_icon_mobile_margin_top')
          )
        ),
        '--search_bar_tablet_padding_top': getPixels(
          Math.max(
            get(styles, 'search_bar_tablet_padding_top'),
            get(styles, 'search_label_tablet_margin_top'),
            get(styles, 'search_icon_tablet_margin_top'),
            get(styles, 'clear_icon_tablet_margin_top')
          )
        ),
        '--search_bar_desktop_padding_top': getPixels(
          Math.max(
            get(styles, 'search_bar_desktop_padding_top'),
            get(styles, 'search_label_desktop_margin_top'),
            get(styles, 'search_icon_desktop_margin_top'),
            get(styles, 'clear_icon_desktop_margin_top')
          )
        ),
        '--search_bar_large_desktop_padding_top': getPixels(
          Math.max(
            get(styles, 'search_bar_large_desktop_padding_top'),
            get(styles, 'search_label_large_desktop_margin_top'),
            get(styles, 'search_icon_large_desktop_margin_top'),
            get(styles, 'clear_icon_large_desktop_margin_top')
          )
        ),

        '--search_bar_mobile_padding_right': getPixels(
          get(styles, 'search_bar_mobile_padding_right') +
            get(styles, 'clear_icon_mobile_width') +
            get(styles, 'search_bar_mobile_horizontal_gutter') +
            get(styles, 'clear_icon_mobile_margin_right') +
            get(styles, 'clear_icon_mobile_margin_left') +
            get(styles, 'search_label_mobile_margin_right')
        ),
        '--search_bar_tablet_padding_right': getPixels(
          get(styles, 'search_bar_tablet_padding_right') +
            get(styles, 'clear_icon_tablet_width') +
            get(styles, 'search_bar_tablet_horizontal_gutter') +
            get(styles, 'clear_icon_tablet_margin_right') +
            get(styles, 'clear_icon_tablet_margin_left') +
            get(styles, 'search_label_tablet_margin_right')
        ),
        '--search_bar_desktop_padding_right': getPixels(
          get(styles, 'search_bar_desktop_padding_right') +
            get(styles, 'clear_icon_desktop_width') +
            get(styles, 'search_bar_desktop_horizontal_gutter') +
            get(styles, 'clear_icon_desktop_margin_right') +
            get(styles, 'clear_icon_desktop_margin_left') +
            get(styles, 'search_label_desktop_margin_right')
        ),
        '--search_bar_large_desktop_padding_right': getPixels(
          get(styles, 'search_bar_large_desktop_padding_right') +
            get(styles, 'clear_icon_large_desktop_width') +
            get(styles, 'search_bar_large_desktop_horizontal_gutter') +
            get(styles, 'clear_icon_large_desktop_margin_right') +
            get(styles, 'clear_icon_large_desktop_margin_left') +
            get(styles, 'search_label_large_desktop_margin_right')
        ),

        '--search_bar_mobile_padding_bottom': getPixels(
          Math.max(
            get(styles, 'search_bar_mobile_padding_bottom'),
            get(styles, 'search_label_mobile_margin_bottom'),
            get(styles, 'search_icon_mobile_margin_bottom'),
            get(styles, 'clear_icon_mobile_margin_bottom')
          )
        ),
        '--search_bar_tablet_padding_bottom': getPixels(
          Math.max(
            get(styles, 'search_bar_tablet_padding_bottom'),
            get(styles, 'search_label_tablet_margin_bottom'),
            get(styles, 'search_icon_tablet_margin_bottom'),
            get(styles, 'clear_icon_tablet_margin_bottom')
          )
        ),
        '--search_bar_desktop_padding_bottom': getPixels(
          Math.max(
            get(styles, 'search_bar_desktop_padding_bottom'),
            get(styles, 'search_label_desktop_margin_bottom'),
            get(styles, 'search_icon_desktop_margin_bottom'),
            get(styles, 'clear_icon_desktop_margin_bottom')
          )
        ),
        '--search_bar_large_desktop_padding_bottom': getPixels(
          Math.max(
            get(styles, 'search_bar_large_desktop_padding_bottom'),
            get(styles, 'search_label_large_desktop_margin_bottom'),
            get(styles, 'search_icon_large_desktop_margin_bottom'),
            get(styles, 'clear_icon_large_desktop_margin_bottom')
          )
        ),

        '--search_bar_mobile_padding_left': getPixels(
          get(styles, 'search_bar_mobile_padding_left') +
            (get(styles, 'search_icon_switch')
              ? get(styles, 'search_icon_mobile_width') +
                get(styles, 'search_icon_mobile_margin_left') +
                get(styles, 'search_icon_mobile_margin_right')
              : 0) +
            get(styles, 'search_label_mobile_margin_left')
        ),
        '--search_bar_tablet_padding_left': getPixels(
          get(styles, 'search_bar_tablet_padding_left') +
            (get(styles, 'search_icon_switch')
              ? get(styles, 'search_icon_tablet_width') +
                get(styles, 'search_icon_tablet_margin_left') +
                get(styles, 'search_icon_tablet_margin_right')
              : 0) +
            get(styles, 'search_label_tablet_margin_left')
        ),
        '--search_bar_desktop_padding_left': getPixels(
          get(styles, 'search_bar_desktop_padding_left') +
            (get(styles, 'search_icon_switch')
              ? get(styles, 'search_icon_desktop_width') +
                get(styles, 'search_icon_desktop_margin_left') +
                get(styles, 'search_icon_desktop_margin_right')
              : 0) +
            get(styles, 'search_label_desktop_margin_left')
        ),
        '--search_bar_large_desktop_padding_left': getPixels(
          get(styles, 'search_bar_large_desktop_padding_left') +
            (get(styles, 'search_icon_switch')
              ? get(styles, 'search_icon_large_desktop_width') +
                get(styles, 'search_icon_large_desktop_margin_left') +
                get(styles, 'search_icon_large_desktop_margin_right')
              : 0) +
            get(styles, 'search_label_large_desktop_margin_left')
        ),

        '--search_bar_mobile_horizontal_gutter_text_indent': getPixels(
          get(styles, 'search_icon_switch')
            ? get(styles, 'search_bar_mobile_horizontal_gutter')
            : 0
        ),
        '--search_bar_tablet_horizontal_gutter_text_indent': getPixels(
          get(styles, 'search_icon_switch')
            ? get(styles, 'search_bar_tablet_horizontal_gutter')
            : 0
        ),
        '--search_bar_desktop_horizontal_gutter_text_indent': getPixels(
          get(styles, 'search_icon_switch')
            ? get(styles, 'search_bar_desktop_horizontal_gutter')
            : 0
        ),
        '--search_bar_large_desktop_horizontal_gutter_text_indent': getPixels(
          get(styles, 'search_icon_switch')
            ? get(styles, 'search_bar_large_desktop_horizontal_gutter')
            : 0
        ),

        '--search_bar_caret_color': getColor(
          get(styles, 'search_bar_caret_color')
        ),

        '--search_label_font_family': get(styles, 'search_label_font_family'),
        '--search_label_mobile_font_size': getPixels(
          get(styles, 'search_label_mobile_font_size')
        ),
        '--search_label_tablet_font_size': getPixels(
          get(styles, 'search_label_tablet_font_size')
        ),
        '--search_label_desktop_font_size': getPixels(
          get(styles, 'search_label_desktop_font_size')
        ),
        '--search_label_large_desktop_font_size': getPixels(
          get(styles, 'search_label_large_desktop_font_size')
        ),
        '--search_label_mobile_line_height': getPixels(
          get(styles, 'search_label_mobile_line_height')
        ),
        '--search_label_tablet_line_height': getPixels(
          get(styles, 'search_label_tablet_line_height')
        ),
        '--search_label_desktop_line_height': getPixels(
          get(styles, 'search_label_desktop_line_height')
        ),
        '--search_label_large_desktop_line_height': getPixels(
          get(styles, 'search_label_large_desktop_line_height')
        ),
        '--search_label_mobile_letter_spacing': getPixels(
          get(styles, 'search_label_mobile_letter_spacing')
        ),
        '--search_label_tablet_letter_spacing': getPixels(
          get(styles, 'search_label_tablet_letter_spacing')
        ),
        '--search_label_desktop_letter_spacing': getPixels(
          get(styles, 'search_label_desktop_letter_spacing')
        ),
        '--search_label_large_desktop_letter_spacing': getPixels(
          get(styles, 'search_label_large_desktop_letter_spacing')
        ),
        '--search_label_font_color': getColor(
          get(styles, 'search_label_font_color')
        ),
        '--search_label_focused_font_color': getColor(
          get(styles, 'search_label_focused_font_color')
        ),
        '--search_label_placeholder_font_color': getColor(
          get(styles, 'search_label_placeholder_font_color')
        ),
        '--search_label_text_transform': get(
          styles,
          'search_label_text_transform'
        ),

        '--search_icon_mobile_width': getPixels(
          get(styles, 'search_icon_mobile_width')
        ),
        '--search_icon_tablet_width': getPixels(
          get(styles, 'search_icon_tablet_width')
        ),
        '--search_icon_desktop_width': getPixels(
          get(styles, 'search_icon_desktop_width')
        ),
        '--search_icon_large_desktop_width': getPixels(
          get(styles, 'search_icon_large_desktop_width')
        ),
        '--search_icon_mobile_height': getPixels(
          get(styles, 'search_icon_mobile_height')
        ),
        '--search_icon_tablet_height': getPixels(
          get(styles, 'search_icon_tablet_height')
        ),
        '--search_icon_desktop_height': getPixels(
          get(styles, 'search_icon_desktop_height')
        ),
        '--search_icon_large_desktop_height': getPixels(
          get(styles, 'search_icon_large_desktop_height')
        ),
        '--search_icon_mobile_margin_top': getPixels(
          get(styles, 'search_icon_mobile_margin_top')
        ),
        '--search_icon_tablet_margin_top': getPixels(
          get(styles, 'search_icon_tablet_margin_top')
        ),
        '--search_icon_desktop_margin_top': getPixels(
          get(styles, 'search_icon_desktop_margin_top')
        ),
        '--search_icon_large_desktop_margin_top': getPixels(
          get(styles, 'search_icon_large_desktop_margin_top')
        ),
        '--search_icon_mobile_margin_right': getPixels(
          get(styles, 'search_icon_mobile_margin_right')
        ),
        '--search_icon_tablet_margin_right': getPixels(
          get(styles, 'search_icon_tablet_margin_right')
        ),
        '--search_icon_desktop_margin_right': getPixels(
          get(styles, 'search_icon_desktop_margin_right')
        ),
        '--search_icon_large_desktop_margin_right': getPixels(
          get(styles, 'search_icon_large_desktop_margin_right')
        ),
        '--search_icon_mobile_margin_bottom': getPixels(
          get(styles, 'search_icon_mobile_margin_bottom')
        ),
        '--search_icon_tablet_margin_bottom': getPixels(
          get(styles, 'search_icon_tablet_margin_bottom')
        ),
        '--search_icon_desktop_margin_bottom': getPixels(
          get(styles, 'search_icon_desktop_margin_bottom')
        ),
        '--search_icon_large_desktop_margin_bottom': getPixels(
          get(styles, 'search_icon_large_desktop_margin_bottom')
        ),
        '--search_icon_mobile_margin_left': getPixels(
          get(styles, 'search_icon_mobile_margin_left') +
            get(styles, 'search_bar_mobile_padding_left')
        ),
        '--search_icon_tablet_margin_left': getPixels(
          get(styles, 'search_icon_tablet_margin_left') +
            get(styles, 'search_bar_tablet_padding_left')
        ),
        '--search_icon_desktop_margin_left': getPixels(
          get(styles, 'search_icon_desktop_margin_left') +
            get(styles, 'search_bar_desktop_padding_left')
        ),
        '--search_icon_large_desktop_margin_left': getPixels(
          get(styles, 'search_icon_large_desktop_margin_left') +
            get(styles, 'search_bar_large_desktop_padding_left')
        ),

        '--clear_icon_mobile_width': getPixels(
          get(styles, 'clear_icon_mobile_width')
        ),
        '--clear_icon_tablet_width': getPixels(
          get(styles, 'clear_icon_tablet_width')
        ),
        '--clear_icon_desktop_width': getPixels(
          get(styles, 'clear_icon_desktop_width')
        ),
        '--clear_icon_large_desktop_width': getPixels(
          get(styles, 'clear_icon_large_desktop_width')
        ),
        '--clear_icon_mobile_height': getPixels(
          get(styles, 'clear_icon_mobile_height')
        ),
        '--clear_icon_tablet_height': getPixels(
          get(styles, 'clear_icon_tablet_height')
        ),
        '--clear_icon_desktop_height': getPixels(
          get(styles, 'clear_icon_desktop_height')
        ),
        '--clear_icon_large_desktop_height': getPixels(
          get(styles, 'clear_icon_large_desktop_height')
        ),
        '--clear_icon_mobile_margin_top': getPixels(
          get(styles, 'clear_icon_mobile_margin_top')
        ),
        '--clear_icon_tablet_margin_top': getPixels(
          get(styles, 'clear_icon_tablet_margin_top')
        ),
        '--clear_icon_desktop_margin_top': getPixels(
          get(styles, 'clear_icon_desktop_margin_top')
        ),
        '--clear_icon_large_desktop_margin_top': getPixels(
          get(styles, 'clear_icon_large_desktop_margin_top')
        ),
        '--clear_icon_mobile_margin_right': getPixels(
          get(styles, 'clear_icon_mobile_margin_right') +
            get(styles, 'search_bar_mobile_padding_right')
        ),
        '--clear_icon_tablet_margin_right': getPixels(
          get(styles, 'clear_icon_tablet_margin_right') +
            get(styles, 'search_bar_tablet_padding_right')
        ),
        '--clear_icon_desktop_margin_right': getPixels(
          get(styles, 'clear_icon_desktop_margin_right') +
            get(styles, 'search_bar_desktop_padding_right')
        ),
        '--clear_icon_large_desktop_margin_right': getPixels(
          get(styles, 'clear_icon_large_desktop_margin_right') +
            get(styles, 'search_bar_large_desktop_padding_right')
        ),
        '--clear_icon_mobile_margin_bottom': getPixels(
          get(styles, 'clear_icon_mobile_margin_bottom')
        ),
        '--clear_icon_tablet_margin_bottom': getPixels(
          get(styles, 'clear_icon_tablet_margin_bottom')
        ),
        '--clear_icon_desktop_margin_bottom': getPixels(
          get(styles, 'clear_icon_desktop_margin_bottom')
        ),
        '--clear_icon_large_desktop_margin_bottom': getPixels(
          get(styles, 'clear_icon_large_desktop_margin_bottom')
        ),
        '--clear_icon_mobile_margin_left': getPixels(
          get(styles, 'clear_icon_mobile_margin_left')
        ),
        '--clear_icon_tablet_margin_left': getPixels(
          get(styles, 'clear_icon_tablet_margin_left')
        ),
        '--clear_icon_desktop_margin_left': getPixels(
          get(styles, 'clear_icon_desktop_margin_left')
        ),
        '--clear_icon_large_desktop_margin_left': getPixels(
          get(styles, 'clear_icon_large_desktop_margin_left')
        ),

        '--search_input_mobile_height': getInputHeight(styles, 'mobile'),
        '--search_input_tablet_height': getInputHeight(styles, 'tablet'),
        '--search_input_desktop_height': getInputHeight(styles, 'desktop'),
        '--search_input_large_desktop_height': getInputHeight(
          styles,
          'large_desktop'
        ),
      },
    };
  } catch (error: any) {
    logger.error(`getSearchV2Vars: ${error.message}`);
    return {};
  }
}

export function getSearchScreenPadding({
  searchScreens,
}: {
  searchScreens: any;
}): string {
  const mobilePaddingTop: number =
    searchScreens?.[0]?.styles?.screen_mobile_padding_top;
  const mobilePaddingBottom: number =
    searchScreens?.[0]?.styles?.screen_mobile_padding_bottom;

  const tabletPaddingTop: number =
    searchScreens?.[0]?.styles?.screen_tablet_padding_top;
  const tabletPaddingBottom: number =
    searchScreens?.[0]?.styles?.screen_tablet_padding_bottom;

  const desktopPaddingTop: number =
    searchScreens?.[0]?.styles?.screen_desktop_padding_top;
  const desktopPaddingBottom: number =
    searchScreens?.[0]?.styles?.screen_desktop_padding_bottom;

  const largeDesktopPaddingTop: number =
    searchScreens?.[0]?.styles?.screen_large_desktop_padding_top;
  const largeDesktopPaddingBottom: number =
    searchScreens?.[0]?.styles?.screen_large_desktop_padding_bottom;

  return `
    mobile:max-tablet:pt-[${mobilePaddingTop}px]
    mobile:max-tablet:pb-[${mobilePaddingBottom}px]
    tablet:max-desktop:pt-[${tabletPaddingTop}px]
    tablet:max-desktop:pb-[${tabletPaddingBottom}px]

    desktop:max-large-desktop:pt-[${desktopPaddingTop}px]
    desktop:max-large-desktop:pb-[${desktopPaddingBottom}px]

    large-desktop:pt-[${largeDesktopPaddingTop}px]
    large-desktop:pb-[${largeDesktopPaddingBottom}px]
    `;
}

function getInputHeight(styles: any, breakpoint: string): string {
  try {
    const fontSize = get(styles, `search_label_${breakpoint}_font_size`);
    const lineHeight = get(styles, `search_label_${breakpoint}_line_height`);
    const paddingTop = get(styles, `search_bar_${breakpoint}_padding_top`);
    const paddingBottom = get(
      styles,
      `search_bar_${breakpoint}_padding_bottom`
    );
    const borderWidth = get(styles, `search_bar_${breakpoint}_border_size`);
    const searchIconHeight = get(styles, `search_icon_${breakpoint}_height`);
    const clearIconHeight = get(styles, `clear_icon_${breakpoint}_height`);
    const searchIconSwitch = get(styles, `search_icon_switch`);

    const contentHeight = Math.max(fontSize, lineHeight);

    const paddingAndBorderHeight: number =
      paddingTop + paddingBottom + borderWidth * 2;

    const iconsHeight: number = searchIconSwitch
      ? Math.max(searchIconHeight, clearIconHeight)
      : clearIconHeight;

    const inputHeight: number = contentHeight + paddingAndBorderHeight;

    if (iconsHeight > inputHeight)
      return `${parseInt(`${iconsHeight + paddingAndBorderHeight}`)}px`;

    return 'auto';
  } catch (error: any) {
    logger.error(`getSearchV2Vars -> getInputHeight: ${error.message}`);
    return 'auto';
  }
}
