const manifest = {
  plugin_id: 1192,
  identifier: 'cell_info_mobile',
  manifest_version: '1.0.6',
  api: {},
  dependency_repository_url: [],
  dependency_name: '',
  author_name: 'Applicaster',
  author_email: 'zapp@applicaster.com',
  name: 'Group Info Cell V2',
  description:
    'Group Info Cell is a special cell that was designed to be used to within group components. \n  The group info component will display information about the group itself.\n  Specifically, it will show the group title, and depending on the cell style you picked, it may show an image as well.',
  type: 0,
  screen: false,
  react_native: true,
  ui_builder_support: true,
  whitelisted_account_ids: [],
  deprecated_since_zapp_sdk: '',
  unsupported_since_zapp_sdk: '',
  targets: ['web'],
  ui_frameworks: ['quickbrick'],
  platform: 'web',
  dependency_version: '1.0.0',
  min_zapp_sdk: '0.0.1',
  general: { fields: [] },
  styles: {
    fields: [
      {
        group: true,
        label: 'Cell',
        folded: false,
        fields: [
          {
            key: 'cell_background_color',
            label: 'Background color',
            initial_value: 'rgba(255, 255, 255, 0)',
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_padding_top',
            label: 'Padding top',
            initial_value: '16',
            type: 'number_input',
          },
          {
            key: 'cell_padding_right',
            label: 'Padding right',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'cell_padding_bottom',
            label: 'Padding bottom',
            initial_value: '16',
            type: 'number_input',
          },
          {
            key: 'cell_padding_left',
            label: 'Padding left',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'cell_border_top_color',
            label: 'Border top color',
            initial_value: 'rgba(255, 255, 255, 0)',
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_border_top_thickness',
            label: 'Border top thickness',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'cell_border_bottom_color',
            label: 'Border bottom color',
            initial_value: 'rgba(255, 255, 255, 0)',
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_border_bottom_thickness',
            label: 'Border bottom thickness',
            initial_value: null,
            type: 'number_input',
          },
        ],
      },
      {
        group: true,
        label: 'Title text label',
        folded: false,
        fields: [
          {
            key: 'title_text_switch',
            label: 'enable',
            initial_value: true,
            type: 'switch',
          },
          {
            key: 'title_text_data_key',
            label: 'Data key',
            label_tooltip:
              'The data key from the feed that we should render in this field',
            rules: 'conditional',
            options: [
              { text: 'feed title', value: 'title' },
              { text: 'other', value: 'other' },
            ],
            initial_value: 'title',
            conditional_fields: [
              { key: 'styles/title_text_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'title_text_custom_data_key',
            label: 'Custom data key',
            conditional_fields: [
              { key: 'styles/title_text_data_key', condition_value: 'other' },
            ],
            type: 'text_input',
          },
          {
            key: 'title_text_typography',
            label: 'Typography',
            options: [
              { text: 'Heading 1', value: 'h1' },
              { text: 'Heading 2', value: 'h2' },
              { text: 'Heading 3', value: 'h3' },
              { text: 'Heading 4', value: 'h4' },
              { text: 'Heading 5', value: 'h5' },
              { text: 'Heading 6', value: 'h6' },
              { text: 'Paragraph', value: 'p' },
              { text: 'Block', value: 'div' },
            ],
            initial_value: 'p',
            type: 'select',
            rules: 'conditional',
            conditional_fields: [
              { key: 'styles/title_text_switch', condition_value: true },
            ],
          },
          {
            key: 'title_text_font_color',
            label: 'Font color',
            initial_value: 'rgba(239, 239, 239, 1)',
            rules: 'conditional',
            conditional_fields: [
              { key: 'styles/title_text_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'title_text_font_family',
            label: 'Font family',
            initial_value: 'Ubuntu-Bold',
            placeholder: 'Select Font',
            rules: 'conditional',
            conditional_fields: [
              { key: 'styles/title_text_switch', condition_value: true },
            ],
            type: 'web_font_selector',
          },
          {
            key: 'title_text_font_size',
            label: 'Font size',
            rules: 'conditional',
            initial_value: 23,
            conditional_fields: [
              { key: 'styles/title_text_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'title_text_letter_spacing',
            label: 'Letter spacing',
            rules: 'conditional',
            initial_value: -0.4,
            conditional_fields: [
              { key: 'styles/title_text_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'title_text_alignment',
            label: 'Text alignment',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Right', value: 'right' },
              { text: 'Center', value: 'center' },
            ],
            rules: 'conditional',
            initial_value: 'left',
            conditional_fields: [
              { key: 'styles/title_text_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'title_text_transform',
            label: 'Text transform',
            options: [
              { text: 'Default', value: 'default' },
              { text: 'Uppercase', value: 'uppercase' },
              { text: 'Lowercase', value: 'lowercase' },
              { text: 'Capitalize', value: 'capitalize' },
            ],
            rules: 'conditional',
            initial_value: 'default',
            conditional_fields: [
              { key: 'styles/title_text_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'title_text_number_of_lines',
            label: 'Number of lines',
            rules: 'conditional',
            initial_value: 1,
            conditional_fields: [
              { key: 'styles/title_text_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'title_text_margin_top',
            label: 'Margin top',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              { key: 'styles/title_text_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'title_text_margin_right',
            label: 'Margin right',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              { key: 'styles/title_text_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'title_text_margin_bottom',
            label: 'Margin bottom',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              { key: 'styles/title_text_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'title_text_margin_left',
            label: 'Margin left',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              { key: 'styles/title_text_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'title_text_line_height',
            label: 'Line height',
            rules: 'conditional',
            initial_value: 28,
            conditional_fields: [
              { key: 'styles/title_text_switch', condition_value: true },
            ],
            type: 'number_input',
          },
        ],
      },
      {
        group: true,
        label: 'Button Element',
        folded: false,
        fields: [
          {
            key: 'button_element_switch',
            label: 'enable',
            initial_value: true,
            type: 'switch',
          },
          {
            key: 'button_element_vertical_position',
            label: 'Vertical Position',
            options: [
              { text: 'Top', value: 'flex-start' },
              { text: 'Middle', value: 'center' },
              { text: 'Bottom', value: 'flex-end' },
            ],
            rules: 'conditional',
            initial_value: 'center',
            conditional_fields: [
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'button_element_horizontal_position',
            label: 'Horizontal Position',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Right', value: 'right' },
            ],
            rules: 'conditional',
            initial_value: 'center',
            conditional_fields: [
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'button_element_background_color',
            label: 'Background color',
            rules: 'conditional',
            initial_value: 'rgba(255, 255, 255, 0)',
            conditional_fields: [
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button_element_corner_radius',
            label: 'Corner Radius',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'button_element_border_color',
            label: 'Border color',
            rules: 'conditional',
            initial_value: 'rgba(255, 255, 255, 0)',
            conditional_fields: [
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button_element_border_thickness',
            label: 'Border Thickness',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'button_element_padding_top',
            label: 'Padding top',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'button_element_padding_right',
            label: 'Padding right',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'button_element_padding_bottom',
            label: 'Padding bottom',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'button_element_padding_left',
            label: 'Padding left',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'button_element_margin_top',
            label: 'Margin top',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'button_element_margin_right',
            label: 'Margin right',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'button_element_margin_bottom',
            label: 'Margin bottom',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'button_element_margin_left',
            label: 'Margin left',
            rules: 'conditional',
            initial_value: 16,
            conditional_fields: [
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
        ],
      },
      {
        group: true,
        label: 'Button text label',
        folded: false,
        fields: [
          {
            key: 'button_text_switch',
            label: 'enable',
            initial_value: true,
            rules: 'conditional',
            conditional_fields: [
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'switch',
          },
          {
            key: 'button_text_data_key',
            label: 'Data key',
            placeholder: 'More',
            label_tooltip:
              'Key where we could find the text for this button in your entry i.e. extensions.group.label \n              If no keys are available this field can be used as the button label i.e. Series, More',
            rules: 'all_conditions',
            conditional_fields: [
              { key: 'styles/button_text_switch', condition_value: true },
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            options: [
              { text: 'More', value: 'More' },
              { text: 'other', value: 'other' },
            ],
            initial_value: 'More',
            type: 'select',
          },
          {
            key: 'button_text_custom_data_key',
            label: 'Custom data key',
            conditional_fields: [
              { key: 'styles/button_text_data_key', condition_value: 'other' },
            ],
            type: 'text_input',
          },
          {
            key: 'button_text_typography',
            label: 'Typography',
            options: [
              { text: 'Heading 1', value: 'h1' },
              { text: 'Heading 2', value: 'h2' },
              { text: 'Heading 3', value: 'h3' },
              { text: 'Heading 4', value: 'h4' },
              { text: 'Heading 5', value: 'h5' },
              { text: 'Heading 6', value: 'h6' },
              { text: 'Paragraph', value: 'p' },
              { text: 'Block', value: 'div' },
            ],
            initial_value: 'p',
            type: 'select',
          },
          {
            key: 'button_text_font_color',
            label: 'Font color',
            initial_value: 'rgba(254, 20, 72, 1)',
            rules: 'all_conditions',
            conditional_fields: [
              { key: 'styles/button_text_switch', condition_value: true },
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button_text_font_color_hover',
            label: 'Hover font color',
            initial_value: 'rgba(190, 14, 53, 1)',
            rules: 'all_conditions',
            conditional_fields: [
              { key: 'styles/button_text_switch', condition_value: true },
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'button_text_font_family',
            label: 'Font family',
            initial_value: 'Ubuntu-Medium',
            placeholder: 'Select Font',
            rules: 'all_conditions',
            conditional_fields: [
              { key: 'styles/button_text_switch', condition_value: true },
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'web_font_selector',
          },
          {
            key: 'button_text_font_size',
            label: 'Font size',
            initial_value: 13,
            rules: 'all_conditions',
            conditional_fields: [
              { key: 'styles/button_text_switch', condition_value: true },
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'button_text_letter_spacing',
            label: 'Letter spacing',
            initial_value: -0.4,
            rules: 'all_conditions',
            conditional_fields: [
              { key: 'styles/button_text_switch', condition_value: true },
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'button_text_transform',
            label: 'Text transform',
            options: [
              { text: 'Default', value: 'default' },
              { text: 'Uppercase', value: 'uppercase' },
              { text: 'Lowercase', value: 'lowercase' },
              { text: 'Capitalize', value: 'capitalize' },
            ],
            initial_value: 'default',
            rules: 'all_conditions',
            conditional_fields: [
              { key: 'styles/button_text_switch', condition_value: true },
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'button_text_margin_top',
            label: 'Margin top',
            initial_value: null,
            rules: 'all_conditions',
            conditional_fields: [
              { key: 'styles/button_text_switch', condition_value: true },
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'button_text_margin_right',
            label: 'Margin right',
            initial_value: null,
            rules: 'all_conditions',
            conditional_fields: [
              { key: 'styles/button_text_switch', condition_value: true },
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'button_text_margin_bottom',
            label: 'Margin bottom',
            initial_value: null,
            rules: 'all_conditions',
            conditional_fields: [
              { key: 'styles/button_text_switch', condition_value: true },
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'button_text_margin_left',
            label: 'Margin left',
            initial_value: null,
            rules: 'all_conditions',
            conditional_fields: [
              { key: 'styles/button_text_switch', condition_value: true },
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'button_text_line_height',
            label: 'Line height',
            initial_value: 24,
            rules: 'all_conditions',
            conditional_fields: [
              { key: 'styles/button_text_switch', condition_value: true },
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'button_text_default_text_decoration',
            label: 'Text decoration',
            options: [
              {
                text: 'None',
                value: 'none',
              },
              {
                text: 'Underline',
                value: 'underline',
              },
              {
                text: 'Strikethrough',
                value: 'line-through',
              },
            ],
            initial_value: 'none',
            rules: 'all_conditions',
            conditional_fields: [
              { key: 'styles/button_text_switch', condition_value: true },
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'select',
          },
        ],
      },
      {
        group: true,
        label: 'Button icon',
        folded: false,
        fields: [
          {
            key: 'button_icon_switch',
            label: 'enable',
            initial_value: true,
            rules: 'conditional',
            conditional_fields: [
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'switch',
          },
          {
            key: 'button_icon_asset',
            label: 'Asset',
            initial_value: '',
            rules: 'all_conditions',
            conditional_fields: [
              { key: 'styles/button_icon_switch', condition_value: true },
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'uploader',
          },
          {
            key: 'button_icon_width',
            label: 'Width',
            initial_value: 24,
            rules: 'all_conditions',
            conditional_fields: [
              { key: 'styles/button_icon_switch', condition_value: true },
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'button_icon_height',
            label: 'Height',
            initial_value: 24,
            rules: 'all_conditions',
            conditional_fields: [
              { key: 'styles/button_icon_switch', condition_value: true },
              { key: 'styles/button_element_switch', condition_value: true },
            ],
            type: 'number_input',
          },
        ],
      },
    ],
  },
  assets: {
    fields: [
      {
        group: true,
        label: 'Badge asset',
        folded: true,
        fields: [
          {
            key: 'badge_asset_switch',
            label: 'enable',
            initial_value: false,
            type: 'switch',
          },
          {
            key: 'badge_asset',
            label: 'Asset',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              { key: 'assets/badge_asset_switch', condition_value: true },
            ],
            type: 'uploader',
          },
          {
            key: 'asset_aspect_ratio',
            label: 'Aspect Ratio',
            label_tooltip:
              'Chose the aspect ratio for your badge, 1x1 is default',
            options: [
              { value: '1x1', text: '1x1' },
              { value: '2x3', text: '2x3' },
              { value: '3x2', text: '3x2' },
              { value: '3x4', text: '3x4' },
              { value: '4x3', text: '4x3' },
              { value: '16x9', text: '16x9' },
            ],
            initial_value: '1x1',
            rules: 'conditional',
            conditional_fields: [
              { key: 'assets/badge_asset_switch', condition_value: true },
            ],
            type: 'select',
          },
          {
            key: 'badge_asset_width',
            label: 'Width',
            initial_value: 44,
            rules: 'conditional',
            conditional_fields: [
              { key: 'assets/badge_asset_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'badge_asset_height',
            label: 'Height',
            initial_value: 44,
            rules: 'conditional',
            conditional_fields: [
              { key: 'assets/badge_asset_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'badge_asset_corner_radius',
            label: 'Corner radius',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              { key: 'assets/badge_asset_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'badge_asset_margin_top',
            label: 'Margin top',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              { key: 'assets/badge_asset_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'badge_asset_margin_right',
            label: 'Margin right',
            rules: 'conditional',
            initial_value: 10,
            conditional_fields: [
              { key: 'assets/badge_asset_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'badge_asset_margin_bottom',
            label: 'Margin bottom',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              { key: 'assets/badge_asset_switch', condition_value: true },
            ],
            type: 'number_input',
          },
          {
            key: 'badge_asset_margin_left',
            label: 'Margin left',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              { key: 'assets/badge_asset_switch', condition_value: true },
            ],
            type: 'number_input',
          },
        ],
      },
    ],
  },
} as const;

export default manifest;
