export function PreloadImage({
  tailwindImageClass,
}: {
  tailwindImageClass: string;
}) {
  return (
    <div
      className={`pointer-events-none h-0 w-0 opacity-0 bg-${tailwindImageClass}`}
    ></div>
  );
}
